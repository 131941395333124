import { useNavigation } from "@react-navigation/native";
import React, { Dispatch, SetStateAction, useState } from "react";
import { MasterData } from "../constants/master";
import {
  IAssetInfo,
  IBotExecutor,
  IBotExecutorByID,
  IBotExecutorStrategySummary,
  IBotPlan,
  ICreateBotExecutor,
  IEditStrategy,
  IOrderLog,
  IUpdateBotExecutor,
  IUpdateBotExecutorReentryStatus,
  IUpdateBotExecutorStatus,
  IUpdateBotExecutorRamaining,
  IUpdateBotExecutorInitialFund,
  IOrderSettings,
} from "../models";
import { ApErrorToast, ApiService, ApSuccessToast } from "../services";
import { TradeSettingSvc } from "../services/TradeSettings";

interface ITradingState {
  mySpotStrategies: Partial<IBotPlan[]>;
  myFutureStrategies: Partial<IBotPlan[]>;
  fixedFutureStrategies: Partial<IBotPlan[]>;
  fixedSpotStrategies: Partial<IBotPlan[]>;
  assetInfo: Partial<IAssetInfo>;
  botExecutors: Partial<IBotExecutor[]>;
  botExecutorByID: Partial<IBotExecutorByID>;
  botExecutorStrategySummary: Partial<IBotExecutorStrategySummary>;
  filteredBotExecutors: Partial<IBotExecutor[]>;
  botPlans: { default: Partial<IBotPlan[]>; my: Partial<IBotPlan[]> };
  orderLogs: IOrderLog[];
  futureBotExecutors: Partial<IBotExecutor[]>;
  spotBotExecutors: Partial<IBotExecutor[]>;
  market: "1" | "2";
  platform: "1" | "2" | "3" | "4";
  botOrderLogs: any[];
  assetInfoStatus: string;
  status: string;
  trendMarginControl: boolean;
  selectedStrategy: Partial<IBotPlan> | undefined;
  isSearching: boolean;
  loading: boolean;
  trendMarginSettings: any[];
  marginSettings: any[];
  klineIntervalSettings: any[];
  takeProfitModeSettings: any[];
  takeProfitSettings: any[];
  macdModeSettings: any[]
  rsiSettings: any[]
  emaSettings: any[]
  kdjSettings: any[]
  orderSettingRows: {prev: any[], curr: any[]}
  orderSettings: {prev: IOrderSettings, curr: IOrderSettings}
  active_MACD: string;
  active_EMA4: string;
  active_TPMODE: string;
  numOfReentry: any;
  isVolatility: boolean;
  ontrend_init_fund: any[];
  entry_size: any[];
  entry_price_diff: any[];
  entry_trailing: any[];
  entry_ta_macd: any[];
  entry_ta_ema4: any[];
  entry_tp_mode: any[];
  entry_macd_type: any[]
  entry_tp_perc: any[];
  entry_tp_trailing_perc: any[];
  entry_ta_rsi: any[]
  entry_ta_ema: any[]
  entry_ta_kdj: any[]
  strategies: any[];
  activeBotPlan: any[];
  entryOrderErr: boolean;
  reentryOrderErr: boolean;
  exitOrderErr: boolean;
  futureUtilisedFund: number;
  spotUtilisedFund: number;
  showSpotTab: boolean;
  evalOnTrendValues: any;
  originalVals: any;
  setOriginalVals: Dispatch<SetStateAction<any>>;
  setEvalOnTrendValues: Dispatch<SetStateAction<any>>;
  setTrendMarginControl: Dispatch<SetStateAction<boolean>>;
  setStatus: Dispatch<SetStateAction<string>>;
  setShowSpotTab: Dispatch<SetStateAction<boolean>>;
  setMarket: Dispatch<SetStateAction<"1" | "2">>;
  setPlatform: Dispatch<SetStateAction<"1" | "2" | "3" | "4">>;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  setExitOrderErr: Dispatch<SetStateAction<boolean>>;
  setEntryOrderErr: Dispatch<SetStateAction<boolean>>;
  setReentryOrderErr: Dispatch<SetStateAction<boolean>>;
  setTrendMarginSettings: Dispatch<SetStateAction<any[]>>;
  setMarginSettings: Dispatch<SetStateAction<any[]>>;
  setKlineIntervalSettings: Dispatch<SetStateAction<any[]>>;
  setTakeProfitModeSettings: Dispatch<SetStateAction<any[]>>;
  setTakeProfitSettings: Dispatch<SetStateAction<any[]>>;
  setMacdModeSettings: Dispatch<SetStateAction<any[]>>;
  setRsiSettings: Dispatch<SetStateAction<any[]>>;
  setEmaSettings: Dispatch<SetStateAction<any[]>>;
  setKdjSettings: Dispatch<SetStateAction<any[]>>;
  setActive_MACD: Dispatch<SetStateAction<any>>;
  setActive_EMA4: Dispatch<SetStateAction<any>>;
  setActive_TPMODE: Dispatch<SetStateAction<any>>;
  setNumOfReentry: Dispatch<SetStateAction<any>>;
  setVolatility: Dispatch<SetStateAction<boolean>>;
  set_entry_trailing: Dispatch<SetStateAction<any[]>>;
  set_entry_size: Dispatch<SetStateAction<any[]>>;
  set_ontrend_init_fund: Dispatch<SetStateAction<any[]>>;
  set_entry_price_diff: Dispatch<SetStateAction<any[]>>;
  set_entry_ta_macd: Dispatch<SetStateAction<any[]>>;
  set_entry_ta_ema4: Dispatch<SetStateAction<any[]>>;
  set_entry_tp_mode: Dispatch<SetStateAction<any[]>>;
  set_entry_macd_type: Dispatch<SetStateAction<any[]>>;
  set_entry_tp_perc: Dispatch<SetStateAction<any[]>>;
  set_entry_tp_trailing_perc: Dispatch<SetStateAction<any[]>>;
  set_entry_ta_rsi: Dispatch<SetStateAction<any[]>>;
  set_entry_ta_ema: Dispatch<SetStateAction<any[]>>;
  set_entry_ta_kdj: Dispatch<SetStateAction<any[]>>;
  setSelectedStrategy: Dispatch<SetStateAction<Partial<IBotPlan> | undefined>>;
  setBotExecutors: Dispatch<SetStateAction<Partial<IBotExecutor[]>>>;
  actionLogs: any[];
  maxSets: any
  setOrderSettingRows: Dispatch<SetStateAction<{prev: any[], curr: any[]}>>;
  setOrderSettings: Dispatch<SetStateAction<{prev: IOrderSettings, curr: IOrderSettings}>>;
  setMaxSets: Dispatch<SetStateAction<any>>;
  generateOrderSettings: (maxSets: string, item: IBotPlan, init?: boolean) => Promise<unknown>
  evalOntrendMultiplier: (e: any) => void;
  triggerNextEntry: (bex_id: string, amount: string) => Promise<void>;
  getBotOrderLogs: (id: string, limit?: string, page?: number, copyTradeId?: string, noLoader?: boolean, providerId?: string) => Promise<void>;
  closeOrder: (payload: any) => void;
  deleteOrder: (payload: any) => void;
  terminateBot: (payload: { bex_ids: string }) => Promise<unknown>;
  initExtraSettings: () => Promise<unknown>;
  getAssetInfo: () => Promise<void>;
  saveOriginal: (vals: any) => void;
  getBotExecutors: (
    market: string,
    init?: boolean,
    showSpot?: boolean
  ) => Promise<void>;
  getBotExecutorByID: (bex_id: string) => void;
  getBotExecutorStrategySummary: (bex_id: string) => Promise<unknown>;
  getBotExecutorDetails: (bex_id: string, init?: boolean) => Promise<void>;
  updateBotExecutorStatus: (payload: IUpdateBotExecutorStatus) => Promise<void>;
  updateBotExecutorReentryStatus: (
    payload: IUpdateBotExecutorReentryStatus
  ) => Promise<void>;
  updateBotExecutorRamaining: (payload: IUpdateBotExecutorRamaining) => Promise<void>;
  updateBotExecutorInitialFund: (payload: IUpdateBotExecutorInitialFund) => Promise<void>;
  updateBotExecutorsStatuses: (arr: any[], status: string) => Promise<unknown>;
  updateBotExecutorsReentryStatuses: (
    arr: any[],
    is_reentry: string
  ) => Promise<unknown>;
  updateBotPlan: (
    payload: Partial<IBotPlan>,
    bexPayload?: IUpdateBotExecutor
  ) => Promise<void>;
  createBotPlan: (
    payload: Partial<IBotPlan>,
    bexPayload?: IUpdateBotExecutor
  ) => Promise<void>;
  createBotExecutor: (payload: ICreateBotExecutor) => Promise<void>;
  updateBotExecutor: (payload: IUpdateBotExecutor) => Promise<void>;
  deleteBotPlan: (payload: { bp_id: string | undefined }) => void;
  filterBotExecutors: (input: string) => void;
  getBotPlans: () => Promise<void>;
  setExtraSettings: (num?: any, init?: boolean, item?: any) => Promise<unknown>;
  setExtraSetting: (
    settings: "macd" | "ema4" | "tp-mode",
    extraVal: string,
    num: any
  ) => void;
  setAllStrategies: () => void;
  setStrategies: Dispatch<SetStateAction<any[]>>;
  getActionLogs: (bexID: string) => void;
  getOrderLogs: (limit?: number, page?: number, platform?: string, noLoader?: boolean) => Promise<void>;
  resetBotPlan: (id: string) => void;
}

const TradingContext = React.createContext<ITradingState>({
  loading: false,
  assetInfo: {},
  botExecutors: [],
  botExecutorByID: {},
  botExecutorStrategySummary: {},
  isSearching: false,
  filteredBotExecutors: [],
  botPlans: { default: [], my: [] },
  trendMarginSettings: [],
  marginSettings: [],
  klineIntervalSettings: [],
  takeProfitModeSettings: [],
  takeProfitSettings: [],
  macdModeSettings: [],
  rsiSettings: [],
  emaSettings: [],
  kdjSettings: [],
  active_MACD: MasterData.INTERVALS[0].value,
  active_EMA4: MasterData.INTERVALS[0].value,
  active_TPMODE: MasterData.TAKE_PROFIT_MODE[0].value,
  numOfReentry: null,
  assetInfoStatus: "",
  isVolatility: true,
  entry_size: [],
  ontrend_init_fund: [],
  entry_price_diff: [],
  entry_trailing: [],
  entry_ta_macd: [],
  entry_ta_ema4: [],
  entry_tp_mode: [],
  entry_macd_type: [],
  entry_tp_perc: [],
  entry_tp_trailing_perc: [],
  entry_ta_rsi: [],
  entry_ta_ema: [],
  entry_ta_kdj: [],
  strategies: [],
  selectedStrategy: {},
  orderLogs: [],
  fixedFutureStrategies: [],
  fixedSpotStrategies: [],
  myFutureStrategies: [],
  mySpotStrategies: [],
  activeBotPlan: [],
  futureBotExecutors: [],
  spotBotExecutors: [],
  entryOrderErr: false,
  reentryOrderErr: false,
  exitOrderErr: false,
  market: "2",
  platform: "1",
  futureUtilisedFund: 0,
  spotUtilisedFund: 0,
  actionLogs: [],
  showSpotTab: false,
  botOrderLogs: [],
  status: "1",
  trendMarginControl: false,
  evalOnTrendValues: null,
  originalVals: {},
  orderSettingRows: null,
  orderSettings: null,
  maxSets: null,
  setMaxSets(){},
  setOrderSettingRows(){},
  setOrderSettings(){},
  setOriginalVals() {},
  setEvalOnTrendValues() {},
  setTrendMarginControl() {},
  evalOntrendMultiplier() {},
  setStatus() {},
  generateOrderSettings(maxSets, item, init) {
      return null as any
  },
  triggerNextEntry() {
    return null;
  },
  closeOrder(payload) {},
  deleteOrder(payload) {},
  getBotOrderLogs(id: string, limit?: string, page?: number, copyTradeId?: string, noLoader?: boolean, providerId?: string) {
    return null
  },
  terminateBot() {
    return new Promise(function (resolve, reject) {});
  },
  saveOriginal() {},
  initExtraSettings() {
    return new Promise(function (resolve, reject) {});
  },
  getAssetInfo() {
    return null;
  },
  getBotExecutors(platform, market) {
    return null;
  },
  getBotExecutorByID(bex_id) {},
  getBotExecutorStrategySummary(bex_id) {
    return new Promise(function (resolve, reject) {});
  },
  getBotExecutorDetails() {
    return null;
  },
  updateBotExecutorStatus(payload) {
    return null;
  },
  updateBotExecutorReentryStatus(payload) {
    return null;
  },
  updateBotExecutorRamaining(payload) {
    return null;
  },
  updateBotExecutorInitialFund(payload) {
    return null;
  },
  updateBotExecutorsStatuses(arr, status) {
    return new Promise(function (resolve, reject) {});
  },
  updateBotExecutorsReentryStatuses(arr, is_reentry) {
    return new Promise(function (resolve, reject) {});
  },
  createBotExecutor() {
    return null;
  },
  updateBotExecutor() {
    return null;
  },
  updateBotPlan(payload) {
    return null;
  },
  createBotPlan(payload) {
    return null;
  },
  deleteBotPlan(payload) {},
  filterBotExecutors(input) {},
  getBotPlans() {
    return null;
  },
  setExtraSettings(settings, num?) {
    return new Promise(function (resolve, reject) {});
  },
  setExtraSetting(settings, extraVal, num) {},

  setShowSpotTab() {},
  setStrategies() {},
  setIsSearching() {},
  setBotExecutors() {},
  setMarket() {},
  setPlatform() {},
  setExitOrderErr() {},
  setEntryOrderErr() {},
  setReentryOrderErr() {},
  setTrendMarginSettings() {},
  setMarginSettings() {},
  setKlineIntervalSettings() {},
  setTakeProfitModeSettings() {},
  setTakeProfitSettings() {},
  setMacdModeSettings(){},
  setRsiSettings(){},
  setEmaSettings(){},
  setKdjSettings(){},
  setNumOfReentry() {},
  setActive_MACD() {},
  setActive_EMA4() {},
  setActive_TPMODE() {},
  setVolatility() {},
  set_entry_size() {},
  set_ontrend_init_fund() {},
  set_entry_price_diff() {},
  set_entry_trailing() {},
  set_entry_ta_macd() {},
  set_entry_ta_ema4() {},
  set_entry_tp_mode() {},
  set_entry_macd_type(){},
  set_entry_tp_perc() {},
  set_entry_tp_trailing_perc() {},
  set_entry_ta_rsi(){},
  set_entry_ta_ema(){},
  set_entry_ta_kdj(){},
  setSelectedStrategy() {},
  setAllStrategies() {},
  getActionLogs() {},
  getOrderLogs(limit?: number, page?: number, platform?: string, noLoader?: boolean) {
    return null;
  },
  resetBotPlan() {},
});

const useTradingContext = () => {
  const context = React.useContext(TradingContext);
  if (context === undefined) {
    throw new Error("app dispatch must be used within app global provider");
  }

  return context;
};

interface IProps {
  children: React.ReactNode;
}

const TradingContextProvider: React.FC<IProps> = ({ children }) => {
  const navigation: any = useNavigation();
  const [loading, setLoading] = useState<boolean>(false);
  const [assetInfo, setAssetInfo] = useState<Partial<IAssetInfo>>({});
  const [botExecutors, setBotExecutors] = useState<Partial<IBotExecutor[]>>([]);
  const [futureBotExecutors, setFutureBotExecutors] = useState<
    Partial<IBotExecutor[]>
  >([]);
  const [spotBotExecutors, setSpotBotExecutors] = useState<
    Partial<IBotExecutor[]>
  >([]);
  const [botPlans, setBotPlans] = useState<{
    default: Partial<IBotPlan[]>;
    my: Partial<IBotPlan[]>;
  }>({ default: [], my: [] });
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [filteredBotExecutors, setFilteredBotExecutors] = useState<
    Partial<IBotExecutor[]>
  >([]);
  const [botExecutorByID, setBotExecutorByID] = useState<
    Partial<IBotExecutorByID>
  >({});
  const [botExecutorStrategySummary, setBotExecutorStrategySummary] = useState<
    Partial<IBotExecutorStrategySummary>
  >({});

  const [marginSettings, setMarginSettings] = useState<any[]>([]);
  const [trendMarginSettings, setTrendMarginSettings] = useState<any[]>([]);
  const [orderSettingRows, setOrderSettingRows] = useState<{prev: any[], curr: any[]}>();
  const [orderSettings, setOrderSettings] = useState<{prev: IOrderSettings, curr: IOrderSettings}>();
  const [maxSets, setMaxSets] = useState<any>();

  const [klineIntervalSettings, setKlineIntervalSettings] = useState<any[]>([]);
  const [macdModeSettings, setMacdModeSettings] = useState<any[]>([]);
  const [takeProfitModeSettings, setTakeProfitModeSettings] = useState<any[]>(
    []
  );
  const [takeProfitSettings, setTakeProfitSettings] = useState<any[]>([]);
  const [rsiSettings, setRsiSettings] = useState<any[]>([]);
  const [emaSettings, setEmaSettings] = useState<any[]>([]);
  const [kdjSettings, setKdjSettings] = useState<any[]>([]);

  const [active_MACD, setActive_MACD] = useState<string>(
    MasterData.INTERVALS[0].value
  );
  const [active_EMA4, setActive_EMA4] = useState<string>(
    MasterData.INTERVALS[0].value
  );
  const [active_TPMODE, setActive_TPMODE] = useState<string>(
    MasterData.TAKE_PROFIT_MODE[0].value
  );
  const [numOfReentry, setNumOfReentry] = useState<any>();
  const [strategies, setStrategies] = useState<any[]>([]);
  const [selectedStrategy, setSelectedStrategy] = useState<
    Partial<IBotPlan> | undefined
  >({});
  const [ontrend_init_fund, set_ontrend_init_fund] = useState<any[]>([]);
  const [entry_size, set_entry_size] = useState<any[]>([]);
  const [entry_price_diff, set_entry_price_diff] = useState<any[]>([]);
  const [entry_trailing, set_entry_trailing] = useState<any[]>([]);
  const [entry_ta_macd, set_entry_ta_macd] = useState<any[]>([]);
  const [entry_ta_ema4, set_entry_ta_ema4] = useState<any[]>([]);
  const [entry_tp_mode, set_entry_tp_mode] = useState<any[]>([]);
  const [entry_macd_type, set_entry_macd_type] = useState<any[]>([]);
  const [entry_tp_perc, set_entry_tp_perc] = useState<any[]>([]);
  const [entry_tp_trailing_perc, set_entry_tp_trailing_perc] = useState<any[]>(
    []
  );
  const [entry_ta_rsi, set_entry_ta_rsi] = useState<any[]>([]);
  const [entry_ta_ema, set_entry_ta_ema] = useState<any[]>([]);
  const [entry_ta_kdj, set_entry_ta_kdj] = useState<any[]>([]);
  const [isVolatility, setVolatility] = useState<boolean>(false);
  const [orderLogs, setOrderLogs] = useState<IOrderLog[]>([]);
  const [fixedFutureStrategies, setFixedFutureStrategies] = useState<
    Partial<IBotPlan[]>
  >([]);
  const [fixedSpotStrategies, setFixedSpotStrategies] = useState<
    Partial<IBotPlan[]>
  >([]);
  const [myFutureStrategies, setMyFutureStrategies] = useState<
    Partial<IBotPlan[]>
  >([]);
  const [mySpotStrategies, setMySpotStrategies] = useState<Partial<IBotPlan[]>>(
    []
  );
  const [activeBotPlan, setActiveBotPlan] = useState<any[]>([]);
  const [entryOrderErr, setEntryOrderErr] = useState<boolean>(false);
  const [reentryOrderErr, setReentryOrderErr] = useState<boolean>(false);
  const [exitOrderErr, setExitOrderErr] = useState<boolean>(false);
  const [market, setMarket] = useState<"1" | "2">("2");
  const [platform, setPlatform] = useState<"1" | "2" | "3" | "4">("1");
  const [futureUtilisedFund, setFutureUtilisedFund] = useState<number>(0);
  const [spotUtilisedFund, setSpotUtilisedFund] = useState<number>(0);
  const [actionLogs, setActionLogs] = useState<any[]>([]);
  const [showSpotTab, setShowSpotTab] = useState<boolean>(false);
  const [botOrderLogs, setBotOrderLogs] = useState<any[]>([]);
  const [assetInfoStatus, setAssetInfoStatus] = useState<string>();
  const [status, setStatus] = useState<string>("1");
  const [trendMarginControl, setTrendMarginControl] = useState<boolean>(false);
  const [evalOnTrendValues, setEvalOnTrendValues] = useState<any>();
  const [ontrendOrders, setOntrendOrders] = useState<any[]>([]);
  const [originalVals, setOriginalVals] = useState<any>({});

  const evalOntrendMultiplier = (e: boolean) => {
    if (ontrend_init_fund?.filter((item) => item != "1").length == 0) {
      let TrendMarginSettings = [];
      if (e == true) {
        for (let i = 0; i < parseInt(numOfReentry); i++) {
          let obj1 = {
            orderList: i + 1,
            name: `trm_multiplier_${i + 1}`,
            multiplier: i + 2,
          };

          TrendMarginSettings.push(obj1);
        }

        TrendMarginSettings.pop();
        setTrendMarginSettings(TrendMarginSettings);
      } else {
        for (let i = 0; i < parseInt(numOfReentry); i++) {
          let obj1 = {
            orderList: i + 1,
            name: `trm_multiplier_${i + 1}`,
            multiplier: selectedStrategy?.ontrend_init_fund?.split(",")[i + 1],
          };

          TrendMarginSettings.push(obj1);
        }

        TrendMarginSettings.pop();
        setTrendMarginSettings(TrendMarginSettings);
      }
    } else {
      let TrendMarginSettings = [];
      if (e == false) {
        for (let i = 0; i < parseInt(numOfReentry); i++) {
          let obj1 = {
            orderList: i + 1,
            name: `trm_multiplier_${i + 1}`,
            multiplier: 1,
          };

          TrendMarginSettings.push(obj1);
        }

        TrendMarginSettings.pop();
        setTrendMarginSettings(TrendMarginSettings);
      } else {
        for (let i = 0; i < parseInt(numOfReentry); i++) {
          let obj1 = {
            orderList: i + 1,
            name: `trm_multiplier_${i + 1}`,
            multiplier: selectedStrategy?.ontrend_init_fund?.split(",")[i + 1],
          };

          TrendMarginSettings.push(obj1);
        }

        TrendMarginSettings.pop();
        setTrendMarginSettings(TrendMarginSettings);
      }
    }
  };

  const resetOntrendMultiplier = () => {
    let TrendMarginSettings = [];

    for (let i = 0; i < parseInt(numOfReentry); i++) {
      let obj1 = {
        orderList: i + 1,
        name: `trm_multiplier_${i + 1}`,
        multiplier: selectedStrategy?.ontrend_init_fund?.split(",")[i + 1],
      };

      TrendMarginSettings.push(obj1);
      // ontrend_init_fund.push(obj1.multiplier)
    }

    TrendMarginSettings.pop();
    setTrendMarginSettings(TrendMarginSettings);
    // set_ontrend_init_fund(ontrend_init_fund);
  };

  const updateBotExecutor = async (payload: IUpdateBotExecutor) => {
    setLoading(true);
    return ApiService.post("/trading/updateBex", payload)
      .then((rs) => {
        ApSuccessToast("Success", rs.data?.msg);
        getBotExecutorDetails(payload.bex_id, true);
        getBotExecutors(market);
        navigation.navigate("BotDetails", {
          bex_id: payload.bex_id,
        });
      })
      .catch((err) => ApErrorToast("Error", err))
      .finally(() => setLoading(false));
  };

  const createBotExecutor = async (payload: ICreateBotExecutor) => {
    setLoading(true);

    let promises: Promise<any>[] = [];

    if (payload.strategies?.length > 0) {
      for (const strategy of payload.strategies) {
        const strategyPayload = {
          ...payload,
          bp_id: strategy.id,
        };
    
        try {
          await ApiService.post("/trading/createBex", strategyPayload);
          ApSuccessToast("Success", "Bot executor created");
        } catch (err) {
          ApErrorToast("Error", err);
        }
      }
    } else {
      promises = [
        ApiService.post("/trading/createBex", payload)
          .then(() => ApSuccessToast("Success", "Bot executor created"))
          .catch((err) => {
            ApErrorToast("Error", err.message || "Failed to create bot executor");
            return Promise.reject(err);
          }),
      ];
    }

    Promise.all(promises)
      .then(() => {
        getBotExecutors(market);
        setStatus("0");
        navigation.navigate("TradingBots", {
          tab: payload.market,
        });
      })
      .catch((err) => {
        ApErrorToast("Error", err);
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const getAssetInfo = (): Promise<void> => {
    return ApiService.get("/trading/getAssetInfo?platform=" + platform)
      .then((rs) => {
        setAssetInfo(rs.data?.data);
        setAssetInfoStatus("1");
        return;
      })
      .catch((err) => {
        ApErrorToast("Error", err);
        setAssetInfoStatus("-1");
      });
  };

  const terminateBot = (payload: { bex_ids: string }) => {
    let promise = new Promise(function (resolve, reject) {
      ApiService.post("/trading/closeAllPositions", payload)
        .then((rs) => {
          resolve(rs.data?.msg);
          ApSuccessToast("Success", rs.data?.msg);
        })
        .catch((err) => {
          ApErrorToast("Error", err);
          reject(err);
        });
    });

    return promise;
  };

  const getBotExecutors = (
    market: string,
    init?: boolean,
    showSpot?: boolean
  ): Promise<void> => {
    init && setLoading(true);

    const p1 = new Promise((resolve, reject) => {
      ApiService.get("/trading/getBex?platform=" + platform + "&market=2").then(
        (rs) => {
          let utilFund: number = 0;
          rs.data?.data.forEach((item: IBotExecutor) => {
            utilFund += parseFloat(item.bep.pos_fund) / parseFloat(item.leverage);
          });
          setFutureUtilisedFund(utilFund);

          resolve(rs.data?.data);
        }
      );
    });

    const p2 = new Promise((resolve, reject) => {
      ApiService.get("/trading/getBex?platform=" + platform + "&market=1").then(
        (rs) => {
          let utilFund: number = 0;
          rs.data?.data.forEach((item: IBotExecutor) => {
            utilFund += parseFloat(item.bep.pos_fund) / parseFloat(item.leverage);
          });
          setSpotUtilisedFund(utilFund);

          resolve(rs.data?.data);
        }
      );
    });

    return Promise.all([p1, p2]).then((values: any) => {
      setBotExecutors([...values[0], ...values[1]]);
      setLoading(false);
    });
  };

  const getBotPlans = (): Promise<void> => {
    setLoading(true);
    return ApiService.get("/trading/getBotPlan")
      .then((rs) => {
        // const fixedFutureBotPlans = rs.data?.data?.default.filter((bp: IBotPlan) => bp.market === "2")
        // const fixedSpotBotPlans = rs.data?.data?.default.filter((bp: IBotPlan) => bp.market === "1")
        // const myFutureBotPlans = rs.data?.data?.my.filter((bp: IBotPlan) => bp.market === "2")
        // const mySpotBotPlans = rs.data?.data?.my.filter((bp: IBotPlan) => bp.market === "1")

        // setFixedFutureStrategies(fixedFutureBotPlans)
        // setFixedSpotStrategies(fixedSpotBotPlans)
        // setMyFutureStrategies(myFutureBotPlans)
        // setMySpotStrategies(mySpotBotPlans)
        setBotPlans({
          default: rs.data?.data?.default?.map((p) => ({ ...p, value: p.id })),
          my: rs.data?.data?.my?.map((p) => ({ ...p, value: p.id })),
        });
        setLoading(false);

        let all = [...rs.data?.data.default, ...rs.data?.data.my];
        let str: any[] = [];

        let obj = {};
        all.forEach((item) => {
          obj = {
            id: item?.id,
            value: item?.id,
            label: item?.label_code,
            market: item?.market,
            account_name: item?.account_name,
            plan_type: item?.plan_type
          };
          str.push(obj);
        });
        setStrategies(str);
        //console.log(str);
        return rs.data?.data;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterBotExecutors = (input: string) => {
    console.log(input);
    input === "" ? setIsSearching(false) : setIsSearching(true);

    const filter: any = botExecutors.filter((item) =>
      item?.symbol.toLowerCase().includes(input.toLowerCase())
    );
    //console.log(input,filter)
    setFilteredBotExecutors(filter);
  };

  const getBotExecutorByID = (bex_id: string) => {
    ApiService.get("/trading/getBotExecById?bex_id=" + bex_id).then((rs) => {
      // console.log(rs.data?.data)
      setBotExecutorByID(rs.data?.data);
    });
  };

  const updateBotPlan = async (
    payload: Partial<IBotPlan>,
    bexPayload?: IUpdateBotExecutor
  ) => {
    setLoading(true);
    return ApiService.post("/trading/updateBotPlan", payload)
      .then((rs) => {
        if (bexPayload) {
          updateBotExecutor(bexPayload);
          getBotPlans();
        } else {
          ApSuccessToast("Success", rs.data?.msg);
          getBotPlans();
          navigation.navigate("Home");
          return rs.data?.msg;
        }
      })
      .catch((err) => {
        ApErrorToast("Error", err);
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const createBotPlan = async (
    payload: Partial<IBotPlan>,
    bexPayload?: IUpdateBotExecutor
  ) => {
    setLoading(true);
    return ApiService.post("/trading/createBotPlan", payload)
      .then((rs) => {
        if (bexPayload) {
          updateBotExecutor({ ...bexPayload, bp_id: rs.data?.msg });
          // createBotExecutor({...bexPayload, bp_id: rs.data?.msg})
          getBotPlans();
        } else {
          ApSuccessToast("Success", "Bot Plan Created");
          getBotPlans();
          navigation.navigate("Home");
          return rs.data?.msg;
        }
      })
      .catch((err) => {
        ApErrorToast("Error", err);
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const triggerNextEntry = async (bex_id: string, amount: string) => {
    return ApiService.post("/trading/triggerNextEntry", { bex_id, amount })
      .then((rs) => {
        ApSuccessToast("", rs.data?.msg);
        getBotExecutorStrategySummary(bex_id);
        getBotExecutors(market);
        return rs.data?.msg;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      });
  };

  const deleteBotPlan = (payload: { bp_id: string | undefined }) => {
    ApiService.post("/trading/deleteBotPlan", payload)
      .then((rs) => {
        getBotPlans();
        ApSuccessToast("Success", rs.data?.msg);
        // createBotExecutor({...bexPayload, bp_id: rs.data?.msg})
        //setBotExecutorByID({...botExecutorByID, status: payload.status})
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const getBotExecutorStrategySummary = (bex_id: string) => {
    let myPromise = new Promise(function (myResolve, myReject) {
      ApiService.get("/trading/getBexSummary?bex_id=" + bex_id).then((rs) => {
        //console.log(rs.data?.data[0])
        setBotExecutorStrategySummary(rs.data?.data);
        myResolve(rs);
        // if(rs.data?.data[0].waterfall_perc > 0)
        // {
        //     setVolatility(false)
        // }
        // else{ setVolatility(true) }
        // if(botPlans.default.filter((bp: any) => bp.id === botExecutorStrategySummary.bp_id).length === 0)
        // {
        //   setActiveBotPlan(botPlans.my.filter((bp: any) => bp.id === botExecutorStrategySummary.bp_id))
        // }
        // else
        // {
        //   setActiveBotPlan(botPlans.default.filter((bp: any) => bp.id === botExecutorStrategySummary.bp_id))
        // }
      });
    });

    return myPromise;
  };

  const getBotExecutorDetails = (bex_id: string, init?: boolean) => {
    init && setLoading(true);
    const p1 = new Promise((resolve, reject) => {
      ApiService.get("/trading/getBexSummary?bex_id=" + bex_id).then((rs) => {
        resolve(rs.data?.data);
        //setBotExecutorByID(rs.data?.data);
      });
    });

    const p2 = new Promise((resolve, reject) => {
      ApiService.get("/trading/getBotExecById?bex_id=" + bex_id).then((rs) => {
        resolve(rs.data?.data);
        //setBotExecutorStrategySummary(rs.data?.data[0]);
      });
    });

    return Promise.all([p1, p2]).then((values: any) => {
      setBotExecutorStrategySummary(values[0]);
      setBotExecutorByID(values[1]);
      setLoading(false);
    });
  };

  const updateBotExecutorStatus = (payload: IUpdateBotExecutorStatus) => {
    return ApiService.post("/trading/updateBexStatus", payload)
      .then((rs) => {
        setBotExecutorByID({ ...botExecutorByID, status: payload.status });
        ApSuccessToast("Success", rs.data?.msg);

        if (payload.status === "2") {
          getBotExecutors(market, true);
          navigation.navigate("TradingBots", {
            tab: "2",
          });
        } else {
          getBotExecutors(market);
        }
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const updateBotExecutorsStatuses = (arr: any[], status: string) => {
    return ApiService.post("/trading/updateBexStatus", {
      bex_ids: arr.toString(),
      status,
    })
      .then((rs) => {
        return rs;
      })
      .catch((err) => {
        ApErrorToast("", err);
        throw err;
      });
  };

  const updateBotExecutorsReentryStatuses = (
    arr: any[],
    is_reentry: string
  ) => {
    let myPromise = new Promise(function (myResolve, myReject) {
      ApiService.post("/trading/updateBexReentry", {
        bex_ids: arr.toString(),
        is_reentry,
      }).then((rs) => {
        myResolve(rs);
      });
    });

    return myPromise;
  };

  const updateBotExecutorReentryStatus = (
    payload: IUpdateBotExecutorReentryStatus
  ) => {
    return ApiService.post("/trading/updateBexReentry", payload)
      .then((rs) => {
        setBotExecutorStrategySummary({
          ...botExecutorStrategySummary,
          is_reentry: payload.is_reentry,
        });
        ApSuccessToast("Success", rs.data?.msg);
        getBotExecutors(market);
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const updateBotExecutorRamaining = (
    payload: IUpdateBotExecutorRamaining
  ) => {
    return ApiService.post("/trading/updateBexRemainingExec", payload)
      .then((rs) => {

        ApSuccessToast("Success", rs.data?.msg);
        getBotExecutors(market);
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const updateBotExecutorInitialFund = (
    payload: IUpdateBotExecutorInitialFund
  ) => {
    return ApiService.post("/trading/updateBexInitFund", payload)
      .then((rs) => {

        ApSuccessToast("Success", rs.data?.msg);
        getBotExecutors(market);
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const initExtraSettings = () => {
    let myPromise = new Promise(function (myResolve, myReject) {
      setTrendMarginSettings([]);
      setMarginSettings([]);
      setKlineIntervalSettings([]);
      setTakeProfitModeSettings([]);
      setTakeProfitSettings([]);
      setMacdModeSettings([])
      setRsiSettings([])
      setEmaSettings([])
      setKdjSettings([])
      myResolve(trendMarginSettings);
    });
    return myPromise;
  };

  const executeGenerate = (
    noOfReentry: number,
    currLength: number,
    limit: number,
    val: number,
    increase?: boolean
  ) => {
    let len2 = originalVals.TakeProfitModeSettings.length;

    let MarginSettings = [];
    let TrendMarginSettings = [];
    let KlineIntervalSettings = [];
    let TakeProfitModeSettings = [];
    let TakeProfitSettings = [];
    let MacdModeSettings = [];
    let RsiSettings = [];
    let EmaSettings = [];
    let KdjSettings = [];
    let EMA4_value: any[] = [];
    let MACD_value: any[] = [];
    let TPMODE_value: any[] = [];
    let nullValues: any[] = [];
    let OntrendOrders: any[] = [];
    let ontrendVal: any[] = [];
    let entrySizeVal: any[] = [];
    let entryPriceDiffVal: any[] = [];
    let entryTraillingReentryVal: any[] = [];
    let macdModeVal: any[] = []
    let entryPercVal: any[] = [];
    let entyTrailingPercVal: any[] = [];
    let rsiVal: any[] = []
    let emaVal: any[] = []
    let kdjVal: any[] = []

    if (currLength > len2 || currLength == len2) {
      MarginSettings = [
        ...originalVals.MarginSettings,
        ...marginSettings.slice(len2 - 1),
      ];
      TrendMarginSettings = [
        ...originalVals.TrendMarginSettings,
        ...trendMarginSettings.slice(len2 - 1),
      ];
      KlineIntervalSettings = [
        ...originalVals.KlineIntervalSettings,
        ...klineIntervalSettings.slice(len2),
      ];
      MacdModeSettings = [
        ...originalVals.MacdModeSettings,
        ...macdModeSettings.slice(len2),
      ]
      TakeProfitModeSettings = [
        ...originalVals.TakeProfitModeSettings,
        ...takeProfitModeSettings.slice(len2),
      ];
      TakeProfitSettings = [
        ...originalVals.TakeProfitSettings,
        ...takeProfitSettings.slice(len2),
      ];
      RsiSettings = [
        ...originalVals.RsiSettings,
        ...rsiSettings.slice(len2),
      ]
      EmaSettings = [
        ...originalVals.EmaSettings,
        ...emaSettings.slice(len2),
      ]
      KdjSettings = [
        ...originalVals.KdjSettings,
        ...kdjSettings.slice(len2),
      ]
      EMA4_value = [
        ...originalVals.entry_ta_ema4,
        ...entry_ta_ema4.slice(len2),
      ];
      MACD_value = [
        ...originalVals.entry_ta_macd,
        ...entry_ta_macd.slice(len2),
      ];
      TPMODE_value = [
        ...originalVals.entry_tp_mode,
        ...entry_tp_mode.slice(len2),
      ];
      ontrendVal = [
        ...originalVals.ontrend_init_fund,
        ...ontrend_init_fund.slice(len2 - 1),
      ];
      entrySizeVal = [
        ...originalVals.entry_size,
        ...entry_size.slice(len2 - 1),
      ];
      entryPriceDiffVal = [
        ...originalVals.entry_price_diff,
        ...entry_price_diff.slice(len2 - 1),
      ];
      entryTraillingReentryVal = [
        ...originalVals.entry_trailing,
        ...entry_trailing.slice(len2 - 1),
      ];
      macdModeVal = [
        ...originalVals.entry_macd_type,
        ...entry_macd_type.slice(len2),
      ]
      entryPercVal = [
        ...originalVals.entry_tp_perc,
        ...entry_tp_perc.slice(len2),
      ];
      entyTrailingPercVal = [
        ...originalVals.entry_tp_trailing_perc,
        ...entry_tp_trailing_perc.slice(len2),
      ];
      OntrendOrders = [
        ...originalVals.OntrendOrders,
        ...ontrendOrders.slice(len2 - 1),
      ];
      rsiVal = [
        ...originalVals.entry_ta_rsi,
        ...entry_ta_rsi.slice(len2),
      ]
      emaVal = [
        ...originalVals.entry_ta_ema,
        ...entry_ta_ema.slice(len2),
      ]
      kdjVal = [
        ...originalVals.entry_ta_kdj,
        ...entry_ta_kdj.slice(len2),
      ]

      for (let i = 0; i < limit; i++) {
        let obj1 = {
          orderList: currLength + i,
          name: `trm_multiplier_${currLength + i}`,
          multiplier: increase ? (val + 1 + i).toString() : val.toString(),
        };

        let obj2 = {
          orderList: currLength + i,
          m_name: `m_multiplier_${currLength + i}`,
          pd_name: `m_price_difference_${currLength + i}`,
          tr_name: `m_trailling_reentry_${currLength + i}`,
          multiplier: entry_size[entry_size.length - 1],
          priceDifference: entry_price_diff[entry_price_diff.length - 1],
          traillingReentry: entry_trailing[entry_trailing.length - 1],
        };

        const klSettings = TradeSettingSvc.klingIntervalSettings(
          currLength + i,
          {
            entry_ta_ema4,
            entry_ta_macd,
          } as any
        );

        const mModeSettings = TradeSettingSvc.macdModeSettings(
          currLength + i,
          { entry_macd_type } as any
        )

        const tProfitModeSettings = TradeSettingSvc.takeProfitModeSettings(
          currLength + i,
          { entry_tp_mode } as any
        );

        const tProfitSettings = TradeSettingSvc.takeProfileSettings(
          currLength + i,
          {
            entry_tp_perc,
            entry_tp_trailing_perc,
          } as any
        );

        const rsiSettings = TradeSettingSvc.rsiSettings(
          currLength + i,
          { entry_ta_rsi } as any
        )

        const emaSettings = TradeSettingSvc.emaSettings(
          currLength + i,
          { entry_ta_ema } as any
        )

        const kdjSettings = TradeSettingSvc.kdjSettings(
          currLength + i,
          { entry_ta_kdj } as any
        )

        TrendMarginSettings.push(obj1);
        MarginSettings.push(obj2);
        KlineIntervalSettings.push(klSettings);
        TakeProfitModeSettings.push(tProfitModeSettings);
        TakeProfitSettings.push(tProfitSettings);
        MacdModeSettings.push(mModeSettings)
        EMA4_value.push(active_EMA4);
        MACD_value.push(active_MACD);
        TPMODE_value.push(active_TPMODE);
        RsiSettings.push(rsiSettings)
        EmaSettings.push(emaSettings)
        KdjSettings.push(kdjSettings)

        // nullValues.push(null);
        ontrendVal.push(obj1.multiplier);
        entrySizeVal.push(obj2.multiplier);
        entryPriceDiffVal.push(obj2.priceDifference);
        entryTraillingReentryVal.push(obj2.traillingReentry);
        macdModeVal.push(mModeSettings.entry_macd_type)
        entryPercVal.push(tProfitSettings.entry_tp_perc);
        entyTrailingPercVal.push(tProfitSettings.entry_tp_trailing_perc);
        OntrendOrders.push((currLength + i + 1).toString());
        rsiVal.push(rsiSettings.entry_ta_rsi)
        emaVal.push(emaSettings.entry_ta_ema)
        kdjVal.push(kdjSettings.entry_ta_kdj)
      }      
    } else if (currLength < len2 && numOfReentry > len2) {
      MarginSettings = [...originalVals.MarginSettings];
      TrendMarginSettings = [...originalVals.TrendMarginSettings];
      KlineIntervalSettings = [...originalVals.KlineIntervalSettings];
      TakeProfitModeSettings = [...originalVals.TakeProfitModeSettings];
      MacdModeSettings= [...originalVals.MacdModeSettings]
      TakeProfitSettings = [...originalVals.TakeProfitSettings];
      RsiSettings = [...originalVals.RsiSettings];
      EmaSettings = [...originalVals.EmaSettings];
      KdjSettings = [...originalVals.KdjSettings];
      EMA4_value = [...originalVals.entry_ta_ema4];
      MACD_value = [...originalVals.entry_ta_macd];
      TPMODE_value = [...originalVals.entry_tp_mode];
      ontrendVal = [...originalVals.ontrend_init_fund];
      entrySizeVal = [...originalVals.entry_size];
      OntrendOrders = [...originalVals.OntrendOrders];
      entryPriceDiffVal = [...originalVals.entry_price_diff];
      entryTraillingReentryVal = [...originalVals.entry_trailing];
      entryPercVal = [...originalVals.entry_tp_perc];
      macdModeVal = [...originalVals.entry_macd_type]
      entyTrailingPercVal = [...originalVals.entry_tp_trailing_perc];
      rsiVal = [...originalVals.entry_ta_rsi]
      emaVal = [...originalVals.entry_ta_ema]
      kdjVal = [...originalVals.entry_ta_kdj]

      for (let i = 0; i < numOfReentry - len2; i++) {
        let obj1 = {
          orderList: len2 + i,
          name: `trm_multiplier_${len2 + i}`,
          multiplier: increase ? (val + 1 + i).toString() : val.toString(),
        };

        let obj2 = {
          orderList: len2 + i,
          m_name: `m_multiplier_${len2 + i}`,
          pd_name: `m_price_difference_${len2 + i}`,
          tr_name: `m_trailling_reentry_${len2 + i}`,
          multiplier:
            originalVals.entry_size[originalVals.entry_size.length - 1],
          priceDifference:
            originalVals.entry_price_diff[
              originalVals.entry_price_diff.length - 1
            ],
          traillingReentry:
            originalVals.entry_trailing[originalVals.entry_trailing.length - 1],
        };

        const klSettings = TradeSettingSvc.klingIntervalSettings(len2 + i, {
          entry_ta_ema4: originalVals.entry_ta_ema4,
          entry_ta_macd: originalVals.entry_ta_macd,
        } as any);

        const mModeSettings = TradeSettingSvc.macdModeSettings(
          len2 + i,
          { entry_macd_type: originalVals.entry_macd_type } as any
        )
        
        const tProfitModeSettings = TradeSettingSvc.takeProfitModeSettings(
          len2 + i,
          { entry_tp_mode: originalVals.entry_tp_mode } as any
        );
        
        const tProfitSettings = TradeSettingSvc.takeProfileSettings(len2 + i, {
          entry_tp_perc: originalVals.entry_tp_perc,
          entry_tp_trailing_perc: originalVals.entry_tp_trailing_perc,
        } as any);

        const rsiSettings = TradeSettingSvc.rsiSettings(
          len2 + i,
          { entry_ta_rsi: originalVals.entry_ta_rsi } as any
        )

        const emaSettings = TradeSettingSvc.emaSettings(
          len2 + i,
          { entry_ta_ema: originalVals.entry_ta_ema } as any
        )

        const kdjSettings = TradeSettingSvc.kdjSettings(
          len2 + i,
          { entry_ta_kdj: originalVals.entry_ta_kdj } as any
        )

        TrendMarginSettings.push(obj1);
        MarginSettings.push(obj2);
        KlineIntervalSettings.push(klSettings);
        TakeProfitModeSettings.push(tProfitModeSettings);
        TakeProfitSettings.push(tProfitSettings);
        MacdModeSettings.push(mModeSettings)
        EMA4_value.push(active_EMA4);
        MACD_value.push(active_MACD);
        TPMODE_value.push(active_TPMODE);
        RsiSettings.push(rsiSettings)
        EmaSettings.push(emaSettings)
        KdjSettings.push(kdjSettings)

        // nullValues.push(null);
        ontrendVal.push(obj1.multiplier);
        entrySizeVal.push(obj2.multiplier);
        entryPriceDiffVal.push(obj2.priceDifference);
        entryTraillingReentryVal.push(obj2.traillingReentry);
        entryPercVal.push(tProfitSettings.entry_tp_perc);
        macdModeVal.push(mModeSettings.entry_macd_type)
        entyTrailingPercVal.push(tProfitSettings.entry_tp_trailing_perc);
        OntrendOrders.push((i + 2).toString());
        rsiVal.push(rsiSettings.entry_ta_rsi)
        emaVal.push(emaSettings.entry_ta_ema)
        kdjVal.push(kdjSettings.entry_ta_kdj)
      }
    } else if (currLength < len2 && numOfReentry < len2) {
      MarginSettings = [...originalVals.MarginSettings];
      TrendMarginSettings = [...originalVals.TrendMarginSettings];
      KlineIntervalSettings = [...originalVals.KlineIntervalSettings];
      TakeProfitModeSettings = [...originalVals.TakeProfitModeSettings];
      MacdModeSettings= [...originalVals.MacdModeSettings]
      TakeProfitSettings = [...originalVals.TakeProfitSettings];
      RsiSettings = [...originalVals.RsiSettings];
      EmaSettings = [...originalVals.EmaSettings];
      KdjSettings = [...originalVals.KdjSettings];
      EMA4_value = [...originalVals.entry_ta_ema4];
      MACD_value = [...originalVals.entry_ta_macd];
      TPMODE_value = [...originalVals.entry_tp_mode];
      OntrendOrders = [...originalVals.OntrendOrders];
      ontrendVal = [...originalVals.ontrend_init_fund];
      entrySizeVal = [...originalVals.entry_size];
      entryPriceDiffVal = [...originalVals.entry_price_diff];
      entryTraillingReentryVal = [...originalVals.entry_trailing];
      entryPercVal = [...originalVals.entry_tp_perc];
      macdModeVal = [...originalVals.entry_macd_type]
      entyTrailingPercVal = [...originalVals.entry_tp_trailing_perc];
      rsiVal = [...originalVals.entry_ta_rsi]
      emaVal = [...originalVals.entry_ta_ema]
      kdjVal = [...originalVals.entry_ta_kdj]

      for (let i = 0; i < len2 - numOfReentry; i++) {
        MarginSettings.pop();
        TrendMarginSettings.pop();
        KlineIntervalSettings.pop();
        TakeProfitModeSettings.pop();
        TakeProfitSettings.pop();
        MacdModeSettings.pop()
        RsiSettings.pop()
        EmaSettings.pop()
        KdjSettings.pop()
        EMA4_value.pop();
        MACD_value.pop();
        TPMODE_value.pop();
        ontrendVal.pop();
        entrySizeVal.pop();
        OntrendOrders.pop();
        entryPriceDiffVal.pop();
        entryTraillingReentryVal.pop();
        entryPercVal.pop();
        macdModeVal.pop()
        entyTrailingPercVal.pop();
        rsiVal.pop()
        emaVal.pop()
        kdjVal.pop()
      }
    } else if (numOfReentry == len2) {
      MarginSettings = [...originalVals.MarginSettings];
      TrendMarginSettings = [...originalVals.TrendMarginSettings];
      KlineIntervalSettings = [...originalVals.KlineIntervalSettings];
      TakeProfitModeSettings = [...originalVals.TakeProfitModeSettings];
      TakeProfitSettings = [...originalVals.TakeProfitSettings];
      MacdModeSettings= [...originalVals.MacdModeSettings]
      RsiSettings = [...originalVals.RsiSettings]
      EmaSettings = [...originalVals.EmaSettings]
      KdjSettings = [...originalVals.KdjSettings]
      EMA4_value = [...originalVals.entry_ta_ema4];
      MACD_value = [...originalVals.entry_ta_macd];
      TPMODE_value = [...originalVals.entry_tp_mode];
      OntrendOrders = [...originalVals.OntrendOrders];
      ontrendVal = [...originalVals.ontrend_init_fund];
      entrySizeVal = [...originalVals.entry_size];
      entryPriceDiffVal = [...originalVals.entry_price_diff];
      entryTraillingReentryVal = [...originalVals.entry_trailing];
      entryPercVal = [...originalVals.entry_tp_perc];
      macdModeVal = [...originalVals.entry_macd_type]
      entyTrailingPercVal = [...originalVals.entry_tp_trailing_perc];
      rsiVal = [...originalVals.entry_ta_rsi]
      emaVal = [...originalVals.entry_ta_ema]
      kdjVal = [...originalVals.entry_ta_kdj]
    }

    return {
      TrendMarginSettings,
      MarginSettings,
      KlineIntervalSettings,
      TakeProfitModeSettings,
      TakeProfitSettings,
      MacdModeSettings,
      RsiSettings,
      EmaSettings,
      KdjSettings,
      EMA4_value,
      MACD_value,
      TPMODE_value,
      OntrendOrders,
      nullValues,
      entrySizeVal,
      entryPriceDiffVal,
      macdModeVal,
      entryTraillingReentryVal,
      ontrendVal,
      entryPercVal,
      entyTrailingPercVal,
      rsiVal,
      emaVal,
      kdjVal
    };
  };

  const setExtraSettings = (numb?: any, init?: boolean, item?: IBotPlan) => {
    // console.log(item?.entry_price_diff,"entry_price_diff..")

    let myPromise = new Promise(function (myResolve, myReject) {
      let MarginSettings = [];
      let TrendMarginSettings = [];
      let KlineIntervalSettings = [];
      let TakeProfitModeSettings = [];
      let MacdModeSettings = []
      let TakeProfitSettings = [];
      let EMA4_value: any[] = [];
      let MACD_value: any[] = [];
      let TPMODE_value: any[] = [];
      let nullValues: any[] = [];
      let OntrendOrders: any[] = [];
      let RsiSettings = []
      let EmaSettings = []
      let KdjSettings = []
      // let entrySizeVal: any[] = [];
      // let entryPriceDiffVal: any[] = [];
      // let entryTraillingReentryVal: any[] = [];
      // let entryPercVal: any[] = [];
      // let entyTrailingPercVal: any[] = [];

      if (init) {
        for (let i = 0; i < parseInt(numb); i++) {
          TrendMarginSettings.push(
            TradeSettingSvc.trendMarginSettings(i, item)
          );
          MarginSettings.push(TradeSettingSvc.marginSettings(i, item));
          KlineIntervalSettings.push(
            TradeSettingSvc.klingIntervalSettings(i, item)
          );
          TakeProfitModeSettings.push(
            TradeSettingSvc.takeProfitModeSettings(i, item)
          );
          MacdModeSettings.push(
            TradeSettingSvc.macdModeSettings(i, item)
          )
          TakeProfitSettings.push(TradeSettingSvc.takeProfileSettings(i, item));
          EMA4_value.push(active_EMA4);
          MACD_value.push(active_MACD);
          TPMODE_value.push(active_TPMODE);
          OntrendOrders.push((i + 2).toString());
          RsiSettings.push(TradeSettingSvc.rsiSettings(i, item));
          EmaSettings.push(TradeSettingSvc.emaSettings(i, item));
          KdjSettings.push(TradeSettingSvc.kdjSettings(i, item));
        }

        MarginSettings.pop();
        TrendMarginSettings.pop();
        OntrendOrders.pop();
        // TakeProfitModeSettings.pop()
        // TakeProfitSettings.pop()
        // TPMODE_value.pop()

        setTrendMarginSettings(TrendMarginSettings);
        setMarginSettings(MarginSettings);
        setKlineIntervalSettings(KlineIntervalSettings);
        setMacdModeSettings(MacdModeSettings)
        setTakeProfitModeSettings(TakeProfitModeSettings);
        setTakeProfitSettings(TakeProfitSettings);
        setOntrendOrders(OntrendOrders);
        setRsiSettings(RsiSettings)
        setEmaSettings(EmaSettings)
        setKdjSettings(KdjSettings)
        set_ontrend_init_fund(
          item?.ontrend_init_fund.split(",").slice(1) as any
        );
        set_entry_size(item?.entry_size.split(",").slice(1) as any);
        set_entry_price_diff(item?.entry_price_diff.split(",").slice(1) as any);
        set_entry_trailing(item?.entry_trailing.split(",").slice(1) as any);
        // set_entry_ta_macd([])
        // set_entry_ta_ema4([])
        // set_entry_tp_mode([])
        set_entry_macd_type(item?.entry_macd_type.split(",") as any)
        set_entry_tp_perc(item?.entry_tp_perc.split(",") as any);
        set_entry_tp_trailing_perc(
          item?.entry_tp_trailing_perc.split(",") as any
        );
        set_entry_ta_macd(item?.entry_ta_macd.split(",") as any);
        set_entry_ta_ema4(item?.entry_ta_ema4.split(",") as any);
        set_entry_tp_mode(item?.entry_tp_mode.split(",") as any);
        set_entry_ta_rsi(item?.entry_ta_rsi.split(",") as any)
        set_entry_ta_ema(item?.entry_ta_ema.split(",") as any)
        set_entry_ta_kdj(item?.entry_ta_kdj.split(",") as any)

        setOriginalVals({
          TrendMarginSettings,
          MarginSettings,
          KlineIntervalSettings,
          MacdModeSettings,
          TakeProfitModeSettings,
          TakeProfitSettings,
          OntrendOrders,
          RsiSettings,
          EmaSettings,
          KdjSettings,
          entry_macd_type: item?.entry_macd_type.split(",") as any,
          ontrend_init_fund: item?.ontrend_init_fund.split(",").slice(1) as any,
          entry_size: item?.entry_size.split(",").slice(1) as any,
          entry_price_diff: item?.entry_price_diff.split(",").slice(1) as any,
          entry_trailing: item?.entry_trailing.split(",").slice(1) as any,
          entry_tp_perc: item?.entry_tp_perc.split(",") as any,
          entry_tp_trailing_perc: item?.entry_tp_trailing_perc.split(
            ","
          ) as any,
          entry_ta_macd: item?.entry_ta_macd.split(",") as any,
          entry_ta_ema4: item?.entry_ta_ema4.split(",") as any,
          entry_tp_mode: item?.entry_tp_mode.split(",") as any,
          entry_ta_rsi: item?.entry_ta_rsi.split(",") as any,
          entry_ta_ema: item?.entry_ta_ema.split(",") as any,
          entry_ta_kdj: item?.entry_ta_kdj.split(",") as any,
        });
        myResolve({
          TrendMarginSettings,
          MarginSettings,
          KlineIntervalSettings,
          MacdModeSettings,
          TakeProfitModeSettings,
          TakeProfitSettings,
          RsiSettings,
          EmaSettings,
          KdjSettings
        });
      } else {
        let len = takeProfitModeSettings.length;
        let len2 = originalVals.TakeProfitModeSettings.length;

        if (numb === "" || parseInt(numb) + 1 === len) {
          myResolve({
            TrendMarginSettings,
            MarginSettings,
            KlineIntervalSettings,
            TakeProfitModeSettings,
            TakeProfitSettings,
            MacdModeSettings,
            RsiSettings,
            EmaSettings,
            KdjSettings
          });
        } else {
          if (parseInt(numb) + 1 + 1 > len) {
            let con = parseInt(numb) + 1 - len;
            let res: any;

            if (len2 > len) {
              if (
                originalVals.OntrendOrders.toString() ===
                originalVals.ontrend_init_fund.toString()
              ) {
                res = executeGenerate(
                  parseInt(numb) + 1,
                  len,
                  con,
                  parseInt(
                    originalVals.OntrendOrders[
                      originalVals.OntrendOrders.length - 1
                    ]
                  ),
                  true
                );
              } else if (
                originalVals.ontrend_init_fund.every((v) => v === "1")
              ) {
                res = executeGenerate(parseInt(numb) + 1, len, con, 1);
              } else {
                res = executeGenerate(
                  parseInt(numb) + 1,
                  len,
                  con,
                  parseInt(
                    originalVals.ontrend_init_fund[
                      originalVals.ontrend_init_fund.length - 1
                    ]
                  )
                );
              }
            } else {
              if (ontrendOrders.toString() === ontrend_init_fund.toString()) {
                res = executeGenerate(
                  parseInt(numb) + 1,
                  len,
                  con,
                  parseInt(ontrendOrders[ontrendOrders.length - 1]),
                  true
                );
              } else if (ontrend_init_fund.every((v) => v === "1")) {
                res = executeGenerate(parseInt(numb) + 1, len, con, 1);
              } else {
                res = executeGenerate(
                  parseInt(numb) + 1,
                  len,
                  con,
                  parseInt(ontrend_init_fund[ontrend_init_fund.length - 1])
                );
              }
            }

            setTrendMarginSettings([...res.TrendMarginSettings]);
            setMarginSettings([...res.MarginSettings]);
            setKlineIntervalSettings([...res.KlineIntervalSettings]);
            setTakeProfitModeSettings([...res.TakeProfitModeSettings]);
            setMacdModeSettings([...res.MacdModeSettings])
            setTakeProfitSettings([...res.TakeProfitSettings]);
            setRsiSettings([...res.RsiSettings])
            setEmaSettings([...res.EmaSettings])
            setKdjSettings([...res.KdjSettings])
            set_entry_size([...res.entrySizeVal]);
            set_entry_price_diff([...res.entryPriceDiffVal]);
            set_entry_trailing([...res.entryTraillingReentryVal]);
            set_entry_tp_perc([...res.entryPercVal]);
            set_entry_macd_type([...res.macdModeVal])
            set_entry_tp_trailing_perc([...res.entyTrailingPercVal]);

            set_ontrend_init_fund([...res.ontrendVal]);
            // set_entry_ta_macd([...res.MACD_value]);
            set_entry_ta_macd([
              ...res.KlineIntervalSettings.map((tp) => tp.MACD),
            ]);
            // set_entry_ta_ema4([...res.EMA4_value]);
            set_entry_ta_ema4([
              ...res.KlineIntervalSettings.map((tp) => tp.EMA4),
            ]);

            
            set_entry_tp_mode([
              ...res.TakeProfitModeSettings.map((tp) => tp.entry_tp_mode),
            ]);
            setOntrendOrders([...res.OntrendOrders]);
            set_entry_ta_rsi([...res.rsiVal])
            set_entry_ta_ema([...res.emaVal])
            set_entry_ta_kdj([...res.kdjVal])
            myResolve(res);
          } else if (parseInt(numb) + 1 < len) {
            TrendMarginSettings = [...trendMarginSettings];
            MarginSettings = [...marginSettings];
            KlineIntervalSettings = [...klineIntervalSettings];
            TakeProfitModeSettings = [...takeProfitModeSettings];
            TakeProfitSettings = [...takeProfitSettings];
            MacdModeSettings = [...macdModeSettings]
            OntrendOrders = [...ontrendOrders];
            RsiSettings = [...rsiSettings]
            EmaSettings = [...emaSettings]
            KdjSettings = [...kdjSettings]

            let con = len - (parseInt(numb) + 1);

            for (let i = 0; i < con; i++) {
              if (TrendMarginSettings.length === ontrend_init_fund.length) {
                ontrend_init_fund.pop();
                entry_size;
              }
              if (MarginSettings.length) entry_size.pop();
              entry_price_diff.pop();
              entry_trailing.pop();
              entry_ta_macd.pop();
              entry_ta_ema4.pop();
              entry_tp_mode.pop();
              entry_tp_perc.pop();
              entry_macd_type.pop()
              entry_tp_trailing_perc.pop();
              entry_ta_rsi.pop()
              entry_ta_ema.pop()
              entry_ta_kdj.pop()

              TrendMarginSettings.pop();
              MarginSettings.pop();
              KlineIntervalSettings.pop();
              TakeProfitModeSettings.pop();
              MacdModeSettings.pop()
              TakeProfitSettings.pop();
              OntrendOrders.pop();
              RsiSettings.pop()
              EmaSettings.pop()
              KdjSettings.pop()
            }

            //console.log(ontrend_init_fund, entry_size, entry_price_diff, entry_trailing);
            setTrendMarginSettings(TrendMarginSettings);
            setMarginSettings(MarginSettings);
            setKlineIntervalSettings(KlineIntervalSettings);
            setTakeProfitModeSettings(TakeProfitModeSettings);
            setMacdModeSettings(MacdModeSettings)
            setTakeProfitSettings(TakeProfitSettings);
            setOntrendOrders(OntrendOrders);
            setRsiSettings(RsiSettings)
            setEmaSettings(EmaSettings)
            setKdjSettings(RsiSettings)
            set_ontrend_init_fund(ontrend_init_fund);
            set_entry_size(entry_size);
            set_entry_price_diff(entry_price_diff);
            set_entry_trailing(entry_trailing);
            set_entry_ta_macd(entry_ta_macd);
            set_entry_ta_ema4(entry_ta_ema4);
            set_entry_tp_mode(entry_tp_mode);
            set_entry_macd_type(entry_macd_type)
            set_entry_tp_perc(entry_tp_perc);
            set_entry_tp_trailing_perc(entry_tp_trailing_perc);
            set_entry_ta_rsi(entry_ta_rsi)
            set_entry_ta_ema(entry_ta_ema)
            set_entry_ta_kdj(entry_ta_kdj)
            myResolve({
              TrendMarginSettings,
              MarginSettings,
              KlineIntervalSettings,
              TakeProfitModeSettings,
              TakeProfitSettings,
              MacdModeSettings,
              RsiSettings,
              EmaSettings,
              KdjSettings
            });
          }
        }
      }
    });
    return myPromise;
  };

  const saveOriginal = (vals: any) => {
    setOriginalVals({
      TrendMarginSettings: [...trendMarginSettings],
      MarginSettings: [...marginSettings],
      KlineIntervalSettings: [...klineIntervalSettings],
      TakeProfitModeSettings: [...takeProfitModeSettings],
      MacdModeSettings: [...macdModeSettings],
      TakeProfitSettings: [...takeProfitSettings],
      RsiSettings: [...rsiSettings],
      EmaSettings: [...emaSettings],
      KdjSettings: [...kdjSettings],
      OntrendOrders: [...ontrendOrders],
      ontrend_init_fund: [...ontrend_init_fund],
      entry_size: [...entry_size],
      entry_price_diff: [...entry_price_diff],
      entry_trailing: [...entry_trailing],
      entry_tp_perc: [...entry_tp_perc],
      entry_macd_type: [...entry_macd_type],
      entry_tp_trailing_perc: [...entry_tp_trailing_perc],
      entry_ta_macd: [...entry_ta_macd],
      entry_ta_ema4: [...entry_ta_ema4],
      entry_tp_mode: [...entry_tp_mode],
      entry_ta_rsi: [...entry_ta_rsi],
      entry_ta_ema: [...entry_ta_ema],
      entry_ta_kdj: [...entry_ta_kdj],
      ...vals,
    });
  };

  const setExtraSetting = (
    settings: "macd" | "ema4" | "tp-mode",
    extraVal: string,
    num: any
  ) => {
    let KlineIntervalSettings = [];
    let TakeProfitModeSettings = [];
    let EMA4_value: any[] = [];
    let MACD_value: any[] = [];
    let TPMODE_value: any[] = [];

    switch (settings) {
      case "macd":
        setActive_MACD(extraVal);
        for (let i = 0; i < parseInt(numOfReentry); i++) {
          let obj = {
            orderList: i + 1,
            EMA4_name: `EMA4_name_${i + 1}`,
            MACD_name: `MACD_name_${i + 1}`,
            EMA4: active_EMA4,
            MACD: extraVal,
          };

          KlineIntervalSettings.push(obj);
          EMA4_value.push(active_EMA4);
          MACD_value.push(extraVal);
        }
        setKlineIntervalSettings(KlineIntervalSettings);
        set_entry_ta_macd(MACD_value);
        set_entry_ta_ema4(EMA4_value);
        break;

      case "ema4":
        setActive_EMA4(extraVal);
        for (let i = 0; i < parseInt(numOfReentry); i++) {
          let obj = {
            orderList: i + 1,
            EMA4_name: `EMA4_name_${i + 1}`,
            MACD_name: `MACD_name_${i + 1}`,
            EMA4: extraVal,
            MACD: active_MACD,
          };

          KlineIntervalSettings.push(obj);
          EMA4_value.push(extraVal);
          MACD_value.push(active_MACD);
        }
        setKlineIntervalSettings(KlineIntervalSettings);
        set_entry_ta_macd(MACD_value);
        set_entry_ta_ema4(EMA4_value);
        break;
    }
  };

  const generateOrderSettings = (maxSets: string, item: IBotPlan, init?: boolean) => {
    let OrderSettingsRow = []
    let OrderSettings: IOrderSettings

    let myPromise = new Promise(function (resolve, reject) {
        if (init) {
          for (let i = 0; i < parseInt(maxSets); i++) {
            OrderSettingsRow.push(
              TradeSettingSvc.orderSettings(i, item)
            );
          }
          setOrderSettingRows({prev: OrderSettingsRow, curr: OrderSettingsRow})
          setOrderSettings({
            curr: {
              set_return: item.set_return.split(",") as any,
              set_sizes_1: item.set_sizes_1.split(",") as any,
              set_sizes_2: item.set_sizes_2.split(",") as any  
            }, 
            prev: {
              set_return: item.set_return.split(",") as any,
              set_sizes_1: item.set_sizes_1.split(",") as any,
              set_sizes_2: item.set_sizes_2.split(",") as any  
            }
          })
          resolve({orderSettingRows, orderSettings})
        } else {
          if (maxSets === "" || parseInt(maxSets) === orderSettingRows.curr.length) {
            resolve({orderSettingRows, orderSettings})
          } else {
            if(parseInt(maxSets) > orderSettingRows.curr.length){
              let currLength = orderSettingRows.curr.length
              let prevLength = orderSettingRows.prev.length
              let con =  parseInt(maxSets) - orderSettingRows.curr.length

              if (currLength > prevLength || currLength == prevLength) {
                OrderSettingsRow = [...orderSettingRows.prev, ...orderSettingRows.curr.slice(prevLength)]
                OrderSettings = {
                  set_return: [...orderSettings.prev.set_return, ...orderSettings.curr.set_return.slice(prevLength)],
                  set_sizes_1: [...orderSettings.prev.set_sizes_1, ...orderSettings.curr.set_sizes_1.slice(prevLength)],
                  set_sizes_2: [...orderSettings.prev.set_sizes_2, ...orderSettings.curr.set_sizes_2.slice(prevLength)]
                }                
                for (let i = 0; i < con; i++) {
                  let val = TradeSettingSvc.orderSettings(currLength + i, orderSettings.curr as any)
                  OrderSettingsRow.push(val);
                  OrderSettings.set_return.push(val.set_return)
                  OrderSettings.set_sizes_1.push(val.set_sizes_1)
                  OrderSettings.set_sizes_2.push(val.set_sizes_2)
                }
                setOrderSettingRows({prev: orderSettingRows.prev, curr: OrderSettingsRow})
                setOrderSettings({prev: orderSettings.prev, curr: OrderSettings})
              } else if (currLength < prevLength && parseInt(maxSets) > prevLength) {
                OrderSettingsRow = [...orderSettingRows.prev]
                OrderSettings = orderSettings.prev
                for (let i = 0; i < parseInt(maxSets) - prevLength; i++) {
                  let val = TradeSettingSvc.orderSettings(prevLength + i, orderSettings.prev as any)
                  OrderSettingsRow.push(val);
                  OrderSettings.set_return.push(val.set_return)
                  OrderSettings.set_sizes_1.push(val.set_sizes_1)
                  OrderSettings.set_sizes_2.push(val.set_sizes_2)
                }
                setOrderSettingRows({prev: orderSettingRows.prev, curr: OrderSettingsRow})
                setOrderSettings({prev: orderSettings.prev, curr: OrderSettings})
              } else if (currLength < prevLength && parseInt(maxSets) < prevLength) {
                OrderSettingsRow = [...orderSettingRows.prev]
                OrderSettings = orderSettings.prev
                for (let i = 0; i < prevLength - parseInt(maxSets); i++) {
                  OrderSettingsRow.pop()
                  OrderSettings.set_return.pop()
                  OrderSettings.set_sizes_1.pop()
                  OrderSettings.set_sizes_2.pop()
                }
                setOrderSettingRows({prev: orderSettingRows.prev, curr: OrderSettingsRow})
                setOrderSettings({prev: orderSettings.prev, curr: OrderSettings})
              } else if (parseInt(maxSets) == prevLength) {
                OrderSettingsRow = [...orderSettingRows.prev]
                OrderSettings = orderSettings.prev
                setOrderSettingRows({prev: orderSettingRows.prev, curr: OrderSettingsRow})
                setOrderSettings({prev: orderSettings.prev, curr: OrderSettings})
              }
              resolve({orderSettingRows, orderSettings})
            } else if(parseInt(maxSets) < orderSettingRows.curr.length){
              OrderSettingsRow = [...orderSettingRows.curr]
              OrderSettings = orderSettings.curr
              let con = orderSettingRows.curr.length - parseInt(maxSets)
              
              for (let i = 0; i < con; i++) {
                OrderSettings.set_return.pop()
                OrderSettings.set_sizes_1.pop()
                OrderSettings.set_sizes_2.pop()
                OrderSettingsRow.pop()
              }

              setOrderSettingRows({prev: orderSettingRows.prev, curr: OrderSettingsRow})
              setOrderSettings({prev: orderSettings.prev, curr: OrderSettings})
              resolve({orderSettingRows, orderSettings})             
            }
          }
        }
    })

    return myPromise;
  }

  const setAllStrategies = () => {
  };

  const getBotOrderLogs = async (id: string, limit?: string, page?: number, copyTradeId?: string, noLoader?: boolean, providerId?: string) => {
    !noLoader && setLoading(true);
    let url = 
      copyTradeId ? `/trading/getOrderLogs?copy_trade_id=${copyTradeId}&limit=${limit}&page=${page}`
      : providerId ? `/metatrader/getOrderLogs?provider_id=${providerId}`
      : `/trading/getOrderLogs?bex_id=${id}&limit=${limit}&page=${page}`

      return ApiService.get(url)
      .then((rs) => {
        setBotOrderLogs(rs.data.data);
      })
      .finally(() => setLoading(false));
  };

  const closeOrder = (payload: any) => {
    ApiService.post("/trading/closeBepo", payload)
      .then((rs) => {
        ApSuccessToast("Success", rs.data?.msg);
        navigation.goBack();
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const deleteOrder = (payload: any) => {
    ApiService.post("/trading/deleteBepo", payload)
      .then((rs) => {
        ApSuccessToast("Success", rs.data?.msg);
        navigation.goBack();
      })
      .catch((err) => ApErrorToast("Error", err));
  };

  const getOrderLogs = (limit?: string | number, page?: number, platform?: string, noLoader?: boolean): Promise<void> => {
    !noLoader && setLoading(true);
    let url = `/trading/getOrderLogs`;

    if (limit && page)
      url = `/trading/getOrderLogs?limit=${limit}&page=${page}`;

    if(platform && platform != 'all')
    url = `/trading/getOrderLogs?limit=${limit}&page=${page}&platform=${platform}`;

    return ApiService.get(url)
      .then((response) => {
        response.data.data.forEach((e: any) => {
          e.time = e.created_at;
          e.title = e.label;
          e.description = e.purpose_desc;
          e.lineColor = "#FF8B7E";
        });
        setOrderLogs(response.data.data);
        return response.data.data;
      })
      .finally(() => setLoading(false));
  };

  const getActionLogs = (bexID: string) => {
    setLoading(true);
    ApiService.get("/trading/getBexActionLogs?bex_id=" + bexID)
      .then((rs) => {
        setActionLogs(rs.data.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const resetBotPlan = (id: string) => {
    setLoading(true);
    return ApiService.post("/trading/resetBotPlan", {id})
    .then((rs) => {
      ApSuccessToast("Success", rs.data?.msg);
      getBotPlans();
      navigation.navigate("Home");
      return rs.data?.msg;
    })
    .catch((err) => {
      ApErrorToast("Error", err);
      throw err;
    })
    .finally(() => setLoading(false));
  };

  return (
    <TradingContext.Provider
      value={{
        entry_ta_kdj,
        kdjSettings,
        set_entry_ta_kdj,
        setKdjSettings,
        emaSettings,
        entry_ta_ema,
        set_entry_ta_ema,
        setEmaSettings,
        entry_ta_rsi,
        rsiSettings,
        set_entry_ta_rsi,
        setRsiSettings,
        deleteOrder,
        maxSets, 
        setMaxSets,
        generateOrderSettings,
        orderSettingRows,
        orderSettings,
        setOrderSettingRows,
        setOrderSettings,
        entry_macd_type,
        macdModeSettings,
        set_entry_macd_type,
        setMacdModeSettings,
        saveOriginal,
        originalVals,
        setOriginalVals,
        evalOnTrendValues,
        setEvalOnTrendValues,
        trendMarginControl,
        setTrendMarginControl,
        evalOntrendMultiplier,
        setStatus,
        status,
        updateBotExecutor,
        triggerNextEntry,
        assetInfoStatus,
        getBotExecutorDetails,
        botOrderLogs,
        getBotOrderLogs,
        closeOrder,
        setShowSpotTab,
        showSpotTab,
        getActionLogs,
        actionLogs,
        createBotExecutor,
        terminateBot,
        futureUtilisedFund,
        spotUtilisedFund,
        platform,
        market,
        setMarket,
        setPlatform,
        exitOrderErr,
        setExitOrderErr,
        reentryOrderErr,
        setReentryOrderErr,
        entryOrderErr,
        setEntryOrderErr,
        setExtraSetting,
        initExtraSettings,
        loading,
        assetInfo,
        getAssetInfo,
        botExecutors,
        getBotExecutors,
        getBotPlans,
        botPlans,
        isSearching,
        filterBotExecutors,
        filteredBotExecutors,
        botExecutorByID,
        getBotExecutorByID,
        updateBotExecutorStatus,
        updateBotExecutorReentryStatus,
        updateBotExecutorRamaining,
        updateBotExecutorInitialFund,
        botExecutorStrategySummary,
        getBotExecutorStrategySummary,
        setExtraSettings,
        updateBotExecutorsStatuses,
        klineIntervalSettings,
        marginSettings,
        trendMarginSettings,
        takeProfitModeSettings,
        takeProfitSettings,
        active_MACD,
        setActive_MACD,
        active_EMA4,
        setActive_EMA4,
        active_TPMODE,
        setActive_TPMODE,
        setTrendMarginSettings,
        setMarginSettings,
        setKlineIntervalSettings,
        setTakeProfitModeSettings,
        setTakeProfitSettings,
        numOfReentry,
        setNumOfReentry,
        strategies,
        setAllStrategies,
        selectedStrategy,
        setSelectedStrategy,
        updateBotExecutorsReentryStatuses,
        updateBotPlan,
        ontrend_init_fund,
        set_ontrend_init_fund,
        entry_size,
        set_entry_size,
        entry_price_diff,
        set_entry_price_diff,
        entry_trailing,
        set_entry_trailing,
        entry_ta_macd,
        set_entry_ta_macd,
        entry_ta_ema4,
        set_entry_ta_ema4,
        entry_tp_mode,
        set_entry_tp_mode,
        entry_tp_perc,
        set_entry_tp_perc,
        entry_tp_trailing_perc,
        set_entry_tp_trailing_perc,
        isVolatility,
        setVolatility,
        orderLogs,
        getOrderLogs,
        createBotPlan,
        myFutureStrategies,
        mySpotStrategies,
        fixedFutureStrategies,
        fixedSpotStrategies,
        activeBotPlan,
        deleteBotPlan,
        futureBotExecutors,
        spotBotExecutors,
        setBotExecutors,
        setIsSearching,
        setStrategies,
        resetBotPlan,
      }}
    >
      {children}
    </TradingContext.Provider>
  );
};

export { TradingContextProvider, useTradingContext };
