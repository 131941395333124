import { Formik, FormikProps, useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { components } from "../components";
import {
  ApButton,
  ApConfirmModal,
  ApDropdown,
  ApSafeAreaView,
  ApSymbolSelection,
  ApTextInput,
  ApToggleInput,
} from "../components/v1";
import { ApLeverageInput } from "../components/v1/input/leverageInput";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import InfoSvg from "../svg/InfoSvg";
import { ApModalAlt } from "../components/Modal";
import { svg } from "../svg";

const BotSettings: React.FC = ({ navigation, route }: any) => {
  const { t } = useTranslation();
  const bex_id = route?.params?.bex_id;
  const formRef = useRef<FormikProps<any>>();
  const [modal, setModal] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const {
    updateBotExecutor,
    botExecutorByID,
    botExecutorStrategySummary,
    createBotExecutor,
    platform,
    market,
    strategies,
    botPlans,
    loading,
  } = useTradingContext();
  const [payload, setPayload] = useState<any>();
  const [items, setItems] = useState<any[]>();
  const [strategyName, setStrategyName] = useState<string>("");
  const [bpID, setBpID] = useState<string>();
  const [allowTargetProfit, setAllowTargetProfit] = useState(false)
  const [hideExecutionRound, setHideExecutionRound] = useState(false)
  const [bpPlanType, setBpPlanType] = useState<string>(null)
  const [showHint, setShowHint] = useState<boolean>(false);
  const [selectedStrategies, setSelectedStrategies] = useState<any[]>([]);
  const [planType1CycleMode, setPlanType1CycleMode] = useState(true);

  const handleDelete = (s: any) => {
    setSelectedStrategies(selectedStrategies?.filter((x) => x.id !== s.id));
  };

  useEffect(() => {
    !bex_id && setItems(strategies.filter((item) => item.market === market));
    !bex_id &&
      setStrategyName(
        strategies.filter((item) => item.market === market)[0].label
      );
    !bex_id &&
      setBpID(strategies.filter((item) => item.market === market)[0].id);
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="Bot Settings"
        goBack={true}
        titleTranlateKey="BotSettings"
      />
    );
  };

  const handleSelectedStrategy = (e: any) => {
    const itm = items.find((item) => item.id === e.id);

    if (itm) {
      setBpID(itm?.id);
      setStrategyName(itm.label);
      setBpPlanType(itm.plan_type)
      if(itm.plan_type == "0"){
        setAllowTargetProfit(false)
        formRef.current.setFieldValue("target_pnl", "0")
        formRef.current.setFieldValue("target_pnl_max", "0")
      }
    }
  };

  const handleCreate = (val: any) => {
    let symbols: any[] = [];
    val.symbols.map((item: any) => {
      symbols.push(item?.value);
    });

    createBotExecutor({
      bp_id: null,
      exec_max: val.exec_max,
      init_fund: val.init_fund,
      leverage: val.leverage,
      platform: platform,
      market: market,
      symbol: symbols.toString(),
      target_pnl: allowTargetProfit ? val.target_pnl : "0",
      target_pnl_max: allowTargetProfit ? val.target_pnl_max : "0",
      strategies: selectedStrategies,
      plan_type1_cycle_mode: val.plan_type1_cycle_mode === true ? "1" : "0",
    } as any).then(() => {
      setStrategyName(items[0].label);
    });
  };

  const handleUpdate = (val: any) => {
    updateBotExecutor({
      bex_id: bex_id,
      exec_max: val.exec_max,
      init_fund: val.init_fund,
      leverage: val.leverage,
      bp_id: botExecutorStrategySummary.bp_id,
    });
  };

  return (
    <>
      <ApSafeAreaView
        style={{ flex: 1, backgroundColor: theme.COLORS.bgColor }}
      >
        {renderHeader()}
        <View style={{ paddingHorizontal: 20, paddingVertical: 20 }}>
          <Formik
            enableReinitialize
            innerRef={formRef as any}
            //validationSchema={FormSchema}
            initialValues={{
              label_code: !!items?.length && items[0].id,
              init_fund: bex_id ? botExecutorByID.init_fund : "1",
              leverage: bex_id
                ? botExecutorByID.leverage
                : market === "1"
                ? "1"
                : "10",
              exec_max: bex_id ? botExecutorByID.exec_max : "9999",
              symbols: [],
              target_pnl: "0",
              target_pnl_max: "0",
              plan_type1_cycle_mode: planType1CycleMode,
            }}
            onSubmit={(val) => {
              bex_id ? handleUpdate(val) : handleCreate(val);
            }}
          >
            {(props: FormikProps<any>) => (
              <>
                {bex_id ? (
                  <></>
                ) : (
                  <View
                    style={{
                      marginBottom: 20,
                      padding: 15,
                      backgroundColor: "#FCF8F6",
                      borderRadius: 10,
                    }}
                  >
                    {/* <ApText
                      translateKey="Strategy"
                      style={{
                        fontSize: 14,
                        color: "#F37021",
                        fontWeight: "500",
                      }}
                    >
                      Strategy
                    </ApText>

                    <View style={{ zIndex: 9999 }}>
                      {strategyName ? (
                        <ApDropdown
                          noBorder
                          onChange={(e: any) => {
                            handleSelectedStrategy(e);
                          }}
                          name="label_code"
                          placeholder={t("ChooseAStrategy")}
                          items={items.filter((itm) => itm.account_name)}
                        />
                      ) : (
                        <></>
                      )}
                    </View> */}
                    <View
                      style={{
                        borderBottomWidth: 1,
                        paddingBottom: 12,
                        borderBottomColor: "#ced6e182",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <ApText
                          translateKey="Strategy"
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                          }}
                        >
                          Strategy
                        </ApText>
                
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate("StrategySelection" as never, {
                              strategies: items,
                              StrategyListSelected: selectedStrategies,
                              onReturn: (selected: any) => {
                                setSelectedStrategies(selected); 
                                if(selected.length == 1) {
                                  handleSelectedStrategy(selected[0]);
                                }
                              },
                            })
                          }
                          style={{
                            backgroundColor: "#F37021",
                            paddingVertical: 3,
                            width: 80,
                            borderRadius: 4,
                          }}
                        >
                          <ApText
                            translateKey="Select"
                            style={{ textAlign: "center", fontSize: 12, color: "white" }}
                          >
                            Select
                          </ApText>
                        </TouchableOpacity>
                      </View>
                
                      <View
                        style={{
                          paddingTop: 7,
                          flexDirection: "row",
                          flex: 1,
                          flexWrap: "wrap",
                        }}
                      >
                        {selectedStrategies?.map((s: any) => (
                          <View
                            key={s.id}
                            style={{
                              marginTop: 10,
                              backgroundColor: "#05B491",
                              width: "fit-content",
                              paddingHorizontal: 10,
                              paddingVertical: 2,
                              borderRadius: 5,
                              flexDirection: "row",
                              alignItems: "center",
                              marginRight: 7,
                            }}
                          >
                            <Text style={{ color: "white", fontSize: 10 }}>{s.label}</Text>
                            <TouchableOpacity
                              style={{ paddingLeft: 5 }}
                              onPress={() => handleDelete(s)}
                            >
                              <svg.CloseWhiteSvg />
                            </TouchableOpacity>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                )}

                <ApText
                  translateKey="ExecutionSettings"
                  style={{
                    fontSize: 14,
                    color: "#F37021",
                    fontWeight: "500",
                    paddingBottom: 10,
                  }}
                />

                <View
                  style={{
                    marginBottom: 20,
                    padding: 15,
                    backgroundColor: "#FCF8F6",
                    borderRadius: 10,
                  }}
                >
                  {bex_id ? (
                    <View
                      style={{
                        borderBottomWidth: 1,
                        paddingBottom: 12,
                        borderBottomColor: "#ced6e182",
                      }}
                    >
                      <View
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <ApText
                          translateKey="Symbol"
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                            marginBottom: 10,
                          }}
                        >
                          Symbol
                        </ApText>

                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#fff",
                            paddingVertical: 1,
                            paddingHorizontal: 10,
                            fontSize: 11,
                            borderRadius: 5,
                          }}
                        >
                          {botExecutorByID.symbol}
                        </ApText>
                      </View>
                    </View>
                  ) : (
                    <ApSymbolSelection name="symbols" />
                  )}

                  <ApTextInput
                    border
                    width={130}
                    height={25}
                    labelTranslateKey="FirstOrderMargin"
                    label="First Order Margin"
                    labelStyle={style.inputLabel}
                    name="init_fund"
                    inputStyle={{
                      ...style.textInput,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    containerStyle={{
                      borderBottomColor: market === "1" && bpPlanType == null ? "#FCF8F6" : "#ced6e182",
                      paddingBottom: market === "1" && bpPlanType == null ? 0 : 15,
                    }}
                    rightIcon={
                      <ApText
                        style={{
                          backgroundColor: "#F37021",
                          color: "#FFF",
                          fontSize: 10,
                          paddingHorizontal: 8,
                          paddingVertical: 0.5,
                          borderTopRightRadius: 6,
                          borderBottomRightRadius: 6,
                        }}
                      >
                        USDT
                      </ApText>
                    }
                  />

                  {market === "1" ? (
                    <></>
                  ) : (
                    <ApTextInput
                      labelIcon={
                        <TouchableOpacity
                          onPress={() => setShowHint(true)}
                        >
                          <InfoSvg strokeColor="#F37021" />
                        </TouchableOpacity>
                      }
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          X
                        </ApText>
                      }
                      containerStyle={{
                        borderBottomColor: bpPlanType == null ? "#FCF8F6" : "#ced6e182",
                        paddingBottom: bpPlanType == null ? 0 : 15,
                      }}
                      border
                      width={130}
                      height={25}
                      label="Leverage"
                      labelTranslateKey="Leverage"
                      labelStyle={style.inputLabel}
                      name="leverage"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  )}

                  {selectedStrategies.length > 1 ? (
                    <></>
                  ) : (
                    <>
                      {bpPlanType == "0" && (
                        <ApTextInput
                          rightIcon={
                            <ApText
                              translateKey="Rounds"
                              style={{
                                backgroundColor: "#F37021",
                                color: "#FFF",
                                fontSize: 10,
                                paddingHorizontal: 8,
                                paddingVertical: 0.5,
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6,
                              }}
                            />
                          }
                          border
                          containerStyle={{
                            borderBottomColor: bpPlanType == "0" ? "#FCF8F6" : "#ced6e182",
                            paddingBottom: bpPlanType == "0" ? 0 : 15,
                          }}
                          width={130}
                          height={25}
                          label="Execution Round"
                          labelTranslateKey="ExecutionRound"
                          labelStyle={style.inputLabel}
                          name="exec_max"
                          inputStyle={{
                            ...style.textInput,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        />
                      )}

                      {bpPlanType == "1" && (
                        <>
                          <ApToggleInput
                            onToggle={(val) => {setAllowTargetProfit(val)}}
                            noBorder={false}
                            labelTranslateKey="AllowTargetProfit"
                            defaultValue={allowTargetProfit}
                            label="Allow Target Profit"
                            name="atp"
                            labelStyle={style.inputLabel}
                          />

                          {allowTargetProfit && (
                            <>
                              <ApTextInput
                                rightIcon={
                                  <ApText
                                    translateKey="USDT"
                                    style={{
                                      backgroundColor: "#F37021",
                                      color: "#FFF",
                                      fontSize: 10,
                                      paddingHorizontal: 8,
                                      paddingVertical: 0.5,
                                      borderTopRightRadius: 6,
                                      borderBottomRightRadius: 6,
                                    }}
                                  />
                                }
                                border
                                width={130}
                                height={25}
                                labelTranslateKey="TargetProfit"
                                label="Target Profit"
                                labelStyle={style.inputLabel}
                                name="target_pnl"
                                inputStyle={{
                                  ...style.textInput,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              />

                              <ApTextInput
                                rightIcon={
                                  <ApText
                                    translateKey="Rounds"
                                    style={{
                                      backgroundColor: "#F37021",
                                      color: "#FFF",
                                      fontSize: 10,
                                      paddingHorizontal: 8,
                                      paddingVertical: 0.5,
                                      borderTopRightRadius: 6,
                                      borderBottomRightRadius: 6,
                                    }}
                                  />
                                }
                                border
                                width={130}
                                height={25}
                                labelTranslateKey="RemainingTargetProfit"
                                label="Remaining Target Profit"
                                labelStyle={style.inputLabel}
                                name="target_pnl_max"
                                inputStyle={{
                                  ...style.textInput,
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              />
                            </>
                          )}

                           <ApToggleInput
                            onToggle={(val) => {
                              setPlanType1CycleMode(val);
                            }}
                            labelTranslateKey="planType1CycleMode"
                            defaultValue={planType1CycleMode}
                            label="Plan Type 1 Cycle Mode"
                            name="plan_type1_cycle_mode"
                            labelStyle={style.inputLabel}
                            noBorder={true}
                          />
                        </>
                      )}
                    </>
                  )}

                </View>

                <View style={{ width: "100%" }}>
                  {bex_id ? (
                    <ApButton
                      loading={loading}
                      disabled={loading}
                      round="lg"
                      onPress={() => {
                        setModal(true);
                      }}
                      labelTranslateKey="Update"
                    />
                  ) : (
                    <ApButton
                      loading={loading}
                      disabled={loading}
                      round="lg"
                      onPress={() => {
                        setCreateModal(true);
                      }}
                      labelTranslateKey="CreateBot"
                    />
                  )}
                </View>

                <ApConfirmModal
                  show={createModal}
                  message="Confirm to create strategy?"
                  messageTranslateKey="ConfirmCreateBot"
                  onDimiss={(rs) => {
                    if (rs) props.handleSubmit();
                    setCreateModal(false);
                  }}
                />

                <ApConfirmModal
                  show={modal}
                  message="Confirm to create strategy?"
                  messageTranslateKey="ConfirmUpdateBot"
                  onDimiss={(rs) => {
                    if (rs) props.handleSubmit();
                    setModal(false);
                  }}
                />
              </>
            )}
          </Formik>
        </View>
      </ApSafeAreaView>

      {showHint && (
        <ApModalAlt
          fallbackRoute="BotSettings"
          transparent={true}
          visible={showHint}
        >
          <>
            <TouchableOpacity
              onPress={() => {
                setShowHint(false);
              }}
              style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
            >
              <svg.closeSvg />
            </TouchableOpacity>

            <View
              style={{
                width: "100%",
                paddingTop: 0,
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}
            >
              <ApText
                style={{ marginTop: 5, marginLeft: 3, fontSize: 14 }}
                translateKey="LeverageHint"
              />
            </View>
          </>
        </ApModalAlt>
      )}
    </>
  );
};

export default BotSettings;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: "20%",
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    borderRadius: 6,
  },
});