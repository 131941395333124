import { Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { ApModalAlt } from "../components/Modal";
import { ApButton } from "../components/v1";
import { ApSettingsInput, ApToggleInput } from "../components/v1/input";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";
import { formatAmt } from "../utils";

interface IProps {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  // takeProfitSettings: any
  // setTakeProfitSettings: any
  // set_entry_tp_perc: any
  // set_entry_tp_trailing_perc: any
}

const TakeProfitSettings: React.FC<IProps> = memo(({ visible, setVisible }) => {
  const { i18n, t } = useTranslation();
  const formRef = useRef<FormikProps<any>>();
  const [initValues, setInitValues] = useState<any>();
  const [isFollowFirstOrder, setIsFollowFirstOrder] = useState<boolean>(false);

  const {
    saveOriginal,
    setTakeProfitSettings,
    takeProfitSettings,
    set_entry_tp_perc,
    set_entry_tp_trailing_perc,
    setExitOrderErr,
  } = useTradingContext();

  useEffect(() => {
    let obj: any;
    takeProfitSettings.map((item: any) => {
      obj = {
        ...obj,
        [item.takeProfitName]:
          item.entry_tp_perc !== null
            ? formatAmt(parseFloat(item.entry_tp_perc) * 100)
            : item.entry_tp_perc,
        [item.traillingTakeProfitName]:
          item.entry_tp_trailing_perc !== null
            ? formatAmt(parseFloat(item.entry_tp_trailing_perc) * 100)
            : item.entry_tp_trailing_perc,
      };
    });
    setInitValues(obj);
    
    // const sameTakeProfitPercentage = isAllValuesSame(obj, "take_profit_percentage_");
    // const sameTraillingTakeProfit = isAllValuesSame(obj, "trailling_take_profit_");

    // if(sameTakeProfitPercentage && sameTraillingTakeProfit) {
    //   setIsFollowFirstOrder(true);
    // }
  }, []);

  // const isAllValuesSame = (data, prefix) => {
  //   if(data) {
  //     const values = Object.keys(data)
  //     .filter((key) => key.startsWith(prefix))
  //     .map((key) => data[key]);

  //     return values.every((val) => val === values[0]);
  //   }
  // };

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 47,
          backgroundColor: "#FCF8F6",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              paddingHorizontal: 20,
              paddingVertical: 12,
            }}
            onPress={() => setVisible(false)}
          >
            <svg.GoBackSvg goBackColor="#F37021" />
          </TouchableOpacity>
        </View>

        <ApText
          translateKey="TakeProfitSettings"
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#F37021",
          }}
        >
          Take Profit Settings
        </ApText>
      </View>
    );
  };

  const handleSave = (val: any) => {
    let names = Object.keys(val);
    let values = Object.values(val);
    // console.log(values);

    // if(values.filter(item => item !== null && item !== '' && item !== undefined).length / 2 !== takeProfitSettings.length)
    // {
    //   setExitOrderErr(true)
    //   ApErrorToast('Error', 'Please fill all orders')
    // }
    // else
    // {
    const takeProfitNames = names.filter((item) =>
      item.includes("take_profit_percentage")
    );
    const traillingTakeProfitNames = names.filter((item) =>
      item.includes("trailling_take_profit")
    );

    setExitOrderErr(false);
    let takeProfitVal: any = [];
    let traillingTakeProfitVal: any = [];
    let n = takeProfitSettings;

    n.forEach((item: any) => {
      if (takeProfitNames.includes(item?.takeProfitName)) {
        if (val[`${item.takeProfitName}`] !== null) {
          item.entry_tp_perc = parseFloat(val[`${item.takeProfitName}`]) / 100;
          item.entry_tp_perc = item.entry_tp_perc?.toString();
        } else {
          item.entry_tp_perc = val[`${item.takeProfitName}`];
        }

        takeProfitVal = [...takeProfitVal, item.entry_tp_perc];
      }

      if (traillingTakeProfitNames.includes(item?.traillingTakeProfitName)) {
        if (val[`${item?.traillingTakeProfitName}`] !== null) {
          item.entry_tp_trailing_perc =
            parseFloat(val[`${item?.traillingTakeProfitName}`]) / 100;
          item.entry_tp_trailing_perc = item.entry_tp_trailing_perc?.toString();
        } else {
          item.entry_tp_trailing_perc = val[`${item?.traillingTakeProfitName}`];
        }

        traillingTakeProfitVal = [
          ...traillingTakeProfitVal,
          item.entry_tp_trailing_perc,
        ];
      }
    });

    setTakeProfitSettings(n);
    set_entry_tp_perc(takeProfitVal);
    set_entry_tp_trailing_perc(traillingTakeProfitVal);
    saveOriginal({
      TakeProfitSettings: n,
      entry_tp_perc: takeProfitVal,
      entry_tp_trailing_perc: traillingTakeProfitVal,
    });
    setVisible(false);
    //console.log(n)
    //navigation.goBack();
    // }
  };

  // const handelEvalValues = (e) => {  
  //   let obj: any = {};
    
  //   if (e === true) {
  //     const firstEntryTpPerc = takeProfitSettings[0].entry_tp_perc;
  //     const firstEntryTpTrailingPerc = takeProfitSettings[0].entry_tp_trailing_perc;

  //     takeProfitSettings.forEach((item: any) => {
  //       obj[item.takeProfitName] = firstEntryTpPerc !== null
  //         ? formatAmt(parseFloat(firstEntryTpPerc) * 100)
  //         : firstEntryTpPerc;
  //       obj[item.traillingTakeProfitName] = firstEntryTpTrailingPerc !== null
  //         ? formatAmt(parseFloat(firstEntryTpTrailingPerc) * 100)
  //         : firstEntryTpTrailingPerc;
  //     });
  //   }else{
  //     takeProfitSettings.map((item: any) => {
  //       obj = {
  //         ...obj,
  //         [item.takeProfitName]:
  //           item.entry_tp_perc !== null
  //             ? formatAmt(parseFloat(item.entry_tp_perc) * 100)
  //             : item.entry_tp_perc,
  //         [item.traillingTakeProfitName]:
  //           item.entry_tp_trailing_perc !== null
  //             ? formatAmt(parseFloat(item.entry_tp_trailing_perc) * 100)
  //             : item.entry_tp_trailing_perc,
  //       };
  //     });
  //   }

  //   setInitValues(obj);
  // };

  const renderContent = () => {
    return (
      <View style={{ flex: 1 }}>
        <View
          style={{
            margin: 15,
            borderRadius: 10,
            marginTop: 15,
            flex: 0.92,
          }}
        >
          {/* <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 5,
              alignItems: "center",
              width: "100%",
            }}
          >
            <ApText
              translateKey="OrderList"
              style={{ color: "#959595", fontSize: 11 }}
            >
              Order List
            </ApText>
            <ApText
              translateKey="TakeProfitPercentage"
              style={{ color: "#959595", fontSize: 11, textAlign: "center" }}
            >
              Take Profit Percentage
            </ApText>
            <ApText
              translateKey="TrailingTakeProfit"
              style={{ color: "#959595", fontSize: 11, textAlign: "center" }}
            >
              Trailing Take Profit
            </ApText>
          </View> */}

          {initValues ? (
            <Formik
              enableReinitialize
              innerRef={formRef as any}
              //validationSchema={FormSchema}
              initialValues={{
                ...initValues,
              }}
              onSubmit={(val) => handleSave(val)}
            >
              {(props: FormikProps<any>) => (
                <>
                  <ApToggleInput
                    disabled={false}
                    onToggle={(e) => {
                      // handelEvalValues(e);
                      setIsFollowFirstOrder(e);
                    }}
                    noBorder={true}
                    labelTranslateKey="EnableInputSyncControl"
                    label="Enable Input Sync Control"
                    name="enable_input_sync_control"
                    labelStyle={style.inputLabel}
                    defaultValue={isFollowFirstOrder}
                  />
                  <View style={{marginTop: 20}}>
                    {takeProfitSettings ? (
                      takeProfitSettings.map((item: any, index: number) => (
                        <View
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingVertical: 20,
                            paddingTop: index == 0 ? 0 : 20,
                            borderBottomColor: "#e6e6e6",
                            borderBottomWidth: 1,
                          }}
                        >
                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="OrderList"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              >
                                Order List
                              </ApText>
                            )}

                            <View
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <ApText
                                translateKey="Order"
                                style={{ color: "#F37021", fontSize: 12 }}
                              >
                                Order
                              </ApText>
                              <ApText
                                style={{ color: "#F37021", fontSize: 12 }}
                              >
                                {" "}
                                #{item?.orderList}
                              </ApText>
                            </View>
                          </View>

                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="TakeProfitPercentage"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              >
                                Take Profit Percentage
                              </ApText>
                            )}

                            <View style={{}}>
                              <ApSettingsInput
                                width={120}
                                height={25}
                                rightIcon={
                                  <ApText
                                    style={{
                                      color: "#F37021",
                                      fontSize: 12,
                                      marginLeft: 10,
                                    }}
                                  >
                                    %
                                  </ApText>
                                }
                                defaultValue={item?.entry_tp_perc}
                                noBorder
                                //labelRight="Volatility Price Percentage"
                                //labelStyle={style.inputLabel}
                                name={item?.takeProfitName}
                                inputStyle={style.textInput}
                                onChange={(text) => {
                                  if (isFollowFirstOrder) {
                                    let obj: any = {};
                                    takeProfitSettings.forEach((item: any, key: number) => {
                                      obj[item.takeProfitName] = text;
                                      obj[item.traillingTakeProfitName] =  item.entry_tp_trailing_perc !== null
                                      ? initValues['trailling_take_profit_'+(key+1)]
                                      : item.entry_tp_trailing_perc;
                                    });
  
                                    setInitValues(obj);                                 
                                  }else{
                                    let obj: any = { ...initValues };
                                    const focusValue = takeProfitSettings[index]?.takeProfitName;

                                    if (focusValue) {
                                      obj[focusValue] = text;
                                    }

                                    setInitValues(obj);                                 
                                  }
                                }}
                              />
                            </View>
                          </View>

                          <View style={{}}>
                            {index == 0 && (
                              <ApText
                                translateKey="TrailingTakeProfit"
                                style={{
                                  color: "#959595",
                                  fontSize: 11,
                                  marginBottom: 20,
                                  alignSelf: "center",
                                }}
                              >
                                Trailing Take Profit
                              </ApText>
                            )}

                            <View style={{}}>
                              <ApSettingsInput
                                width={120}
                                height={25}
                                rightIcon={
                                  <ApText
                                    style={{
                                      color: "#F37021",
                                      fontSize: 12,
                                      marginLeft: 10,
                                    }}
                                  >
                                    %
                                  </ApText>
                                }
                                defaultValue={item?.entry_tp_trailing_perc}
                                noBorder
                                //labelRight="Volatility Price Percentage"
                                //labelStyle={style.inputLabel}
                                name={item?.traillingTakeProfitName}
                                inputStyle={style.textInput}
                                onChange={(text) => {
                                  if (isFollowFirstOrder) {
                                    let obj: any = {};
                                    takeProfitSettings.forEach((item: any, key: number) => {
                                      obj[item.takeProfitName] = item.entry_tp_perc !== null
                                      ? initValues['take_profit_percentage_'+(key+1)]
                                      : item.entry_tp_perc
                                      obj[item.traillingTakeProfitName] = text;
                                    });
                                    setInitValues(obj);                                 
                                  }else{
                                    let obj: any = { ...initValues };
                                    const focusValue = takeProfitSettings[index]?.traillingTakeProfitName;

                                    if (focusValue) {
                                      obj[focusValue] = text;
                                    }

                                    setInitValues(obj);                                 
                                  }
                                }}
                              />
                            </View>
                          </View>
                        </View>
                      ))
                    ) : (
                      <></>
                    )}
                  </View>

                  <View style={{ marginTop: 20 }}>
                    <ApButton
                      round="lg"
                      label="Save"
                      labelTranslateKey="Save"
                      onPress={() => props.handleSubmit()}
                    />
                  </View>
                </>
              )}
            </Formik>
          ) : (
            <></>
          )}
        </View>
      </View>
    );
  };

  return (
    <ApModalAlt fallbackRoute="EditStrategy" visible={visible}>
      <ScrollView style={{ flex: 1 }}>
        {renderHeader()}
        {renderContent()}
      </ScrollView>
    </ApModalAlt>
  );
});

export default TakeProfitSettings;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: 80,
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    padding: 3,
    borderRadius: 6,
  },
});
