import React from "react";
import {View} from "react-native";
import {ApText} from "../../../../components/v1/typography";
import {useTradingContext} from "../../../../context";

export const Detection = ({}) => {
  const {botExecutorStrategySummary, botExecutorByID} = useTradingContext();

  return (
    <View
      style={{
        backgroundColor: "#FFF8F4",
        padding: 12,
        marginBottom: 20,
      }}
    >
      <ApText
        style={{
          color: "#F37021",
          fontSize: 16,
          fontWeight: "500",
          paddingBottom: 15,
        }}
        translateKey="Detection"
      />
      {Array.isArray(botExecutorStrategySummary?.triggers) &&
        botExecutorStrategySummary.triggers.map((data, index) => (
          <View
            key={index}
            style={{flexDirection: "row", alignItems: "center"}}
          >
            <ApText style={{fontSize: 12, flex:1 }}>{data.display_1}</ApText>
            <ApText style={{fontSize: 12, color: "#F37021",  flex:1}}>
              {data?.display_2} ({data?.display_3})
            </ApText>
          </View>
        ))}
    </View>
  );
};
